var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "feature-item",
      class: { "feature-item--left": _vm.featureDetails.isImgLeft },
    },
    [
      _c("div", { staticClass: "feature-item__content" }, [
        _c("div", { staticClass: "feature-item__header" }, [
          _c("div", { staticClass: "feature-item__ico-wrapper" }, [
            _c("svg", { staticClass: "feature-item__ico" }, [
              _c("use", {
                attrs: { href: "#feature-" + _vm.featureDetails.ico },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "feature-item__name" }, [
            _vm._v(_vm._s(_vm.featureDetails.sectionName)),
          ]),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "feature-item__title" }, [
          _vm._v(_vm._s(_vm.featureDetails.title)),
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "feature-item__list" },
          _vm._l(_vm.featureDetails.featureList, function (item) {
            return _c("li", { staticClass: "feature-item__text" }, [
              _c("svg", { staticClass: "feature-item__checkmark" }, [
                _c("use", { attrs: { href: "#checkmark" } }),
              ]),
              _vm._v("\n        " + _vm._s(item) + "\n      "),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "feature-item__img-wrapper" }, [
        _c("img", {
          staticClass: "feature-item__img",
          attrs: {
            src: "/images/landing/feature-" + _vm.featureDetails.img + ".png",
            alt: _vm.featureDetails.sectionName,
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }