var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("ButtonRippleEffect", { staticClass: "ripple-outer--light" }, [
        _c(
          "a",
          {
            staticClass: "login__button button button--label",
            attrs: { href: "/sign/login" },
          },
          [
            _c("svg", { staticClass: "login__ico" }, [
              _c("use", { attrs: { href: "#login" } }),
            ]),
            _vm._v("\n      Login\n    "),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }