<template>
  <div class="input-dropdown">
    <label class="input-dropdown__search">
      <input
        type="search"
        class="input-dropdown__input"
        tabindex="0"
        ref="countrySearchInput"
        :value="searchField"
        @input="searchField = $event.target.value"
      >
      <svg class="input-dropdown__ico">
        <use href="#search"/>
      </svg>
    </label>
    <div class="input-dropdown__list">
      <li class="input-dropdown__item" v-for="countryCode in filteredCountryCodes" @click="chooseCountryCode(countryCode)">
        <div class="input-dropdown__img-wrapper">
          <img class="input-dropdown__img" :src="countryCode.flag" alt="country">
        </div>
        <span class="input-dropdown__text"> {{ countryCode.name }} </span>
      </li>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['countryCodes'],
    data() {
      return {
        searchField: ''
      }
    },
    mounted() {
      this.$refs.countrySearchInput.focus();
    },
    computed: {
      filteredCountryCodes() {
        if (this.searchField.length > 0) {
          return this.countryCodes.filter((countryCode) => {
            return countryCode.name.toLowerCase().indexOf(this.searchField.toLowerCase()) !== -1;
          });
        } else {
          return this.countryCodes;
        }
      }
    },
    methods: {
      chooseCountryCode(countryCode) {
        this.$emit('country-code-select', countryCode);
      }
    }
  }
</script>

<style scoped>
  .input-dropdown {
    width: 100%;
    border: 1px solid #00A6FF;
    border-radius: 4px;
  }

  .input-dropdown__search {
    display: block;
    position: relative;
    margin: 10px;
    box-sizing: border-box;
  }

  .input-dropdown__ico {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 19px;
    height: 20px;
    fill: var(--text-color-secondary);
    z-index: 1;
  }

  .input-dropdown__input {
    display: block;
    width: 100%;
    padding: 10px 10px 10px 35px;
    color: var(--text-color-primary);
    font-size: 1.25rem;
    line-height: 1;
    background: var(--color-white);
    border: 1px solid #DCDFE5;
    border-radius: 4px;
    box-sizing: border-box;
    -webkit-appearance: none;
  }

  .input-dropdown__list {
    max-height: 240px;
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .input-dropdown__item {
    position: relative;
    padding: 14px 17px 12px 0;
    cursor: pointer;

    &:hover {
      background: #f5f6f8;
    }
  }

  .input-dropdown__img-wrapper {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 20px;
    height: 14px;
    transform: translateY(-50%);
  }

  .input-dropdown__img {
    max-width: 100%; /*for IE*/
  }

  .input-dropdown__text {
    display: inline-block;
    padding-left: 45px;
    color: var(--text-color-primary);

    .is-highlighted {
      background: #e4f6ff;
    }

  }
</style>
