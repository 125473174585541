var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      !_vm.$route.meta.hideHeader ? _c("TheHeader") : _vm._e(),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      !_vm.$route.meta.hideBooking
        ? _c("BookingForm", { attrs: { id: "booking" } })
        : _vm._e(),
      _vm._v(" "),
      _c("AppPromo"),
      _vm._v(" "),
      _c("TheFooter"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }