var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "observe-visibility",
          rawName: "v-observe-visibility",
          value: {
            callback: (isVisible, entry) =>
              _vm.isViewableNow(isVisible, entry, "header"),
            once: true,
          },
          expression:
            "{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, 'header'), once: true}",
        },
      ],
      staticClass: "landing-pricing",
      class: { visible: _vm.showAnimation },
    },
    [
      _c("div", { staticClass: "landing-pricing__wrapper wrapper" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "landing-pricing__list" },
          _vm._l(_vm.getPlans(), function (plan) {
            return _c(
              "div",
              { staticClass: "landing-pricing__item" },
              [
                _c("PricingPlan", {
                  attrs: { planDetails: plan, withCounter: false },
                }),
              ],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "landing-pricing__box" },
          [
            _c(
              "router-link",
              {
                staticClass: "landing-pricing__link",
                attrs: { to: { name: "landingPricingPage" } },
              },
              [
                _vm._v("\n        Learn more about pricing plans\n        "),
                _c("svg", { staticClass: "landing-pricing__ico" }, [
                  _c("use", { attrs: { href: "#arrow-right-circle" } }),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "landing-pricing__box landing-pricing__box--img only-tablet-desktop",
        },
        [
          _c("svg", { staticClass: "landing-pricing__img" }, [
            _c("use", { attrs: { href: "#abstract-pricing" } }),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass:
                "landing-pricing__img landing-pricing__img--right only-tablet-desktop",
            },
            [_c("use", { attrs: { href: "#abstract-pricing" } })]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "landing-pricing__header" }, [
      _c(
        "p",
        {
          staticClass:
            "landing-pricing__title landing-pricing__title--section section-title",
        },
        [_vm._v("PRICING")]
      ),
      _vm._v(" "),
      _c(
        "h2",
        {
          staticClass:
            "landing-pricing__title landing-pricing__title--secondary secondary-title",
        },
        [_vm._v("Sign up to GoPlanr for free today")]
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "landing-pricing__title landing-pricing__title--subtitle text-secondary",
        },
        [
          _vm._v(
            "GoPlanr only charges for what you use with no hidden fees or extras."
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }