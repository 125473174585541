<template>
  <footer class="footer">
    <div class="footer__wrapper wrapper">
      <div class="footer__content">

        <div class="footer__item">
            <div class="footer__logo">
              <img class="footer__img" src="/images/landing/gccs-logo.svg" alt="goplanr-logo">
            </div>
        </div>
        <div class="footer__item">
          <nav class="footer__navigation">
            <div class="footer__item">
              <a class="footer__link" href="javascript:void(0);" @click="isShownPrivacyPolicy = true">Privacy Policy</a>
            </div>
            <div class="footer__item">
              <a class="footer__link" href="javascript:void(0);" @click="areShownTerms = true">Terms & Conditions</a>
            </div>
            <div class="footer__item">
              <a class="footer__link" href="javascript:void(0);" @click="isShownContactForm = true">Contact Us</a>
            </div>
          </nav>
        </div>
        <div class="footer__item footer__item--social">
          <div class="footer__box">
            <div class="footer__social">
              <SocialLinks/>
            </div>
          </div>

          <div class="footer__box">
            <div class="footer__info">© GoPlanr {{ currentYear }}. All rights reserved</div>
          </div>

        </div>

      </div>
    </div>

    <LandingPopup :is-open="isShownContactForm" @close="closeContactFormPopup()" title="Contact us">
      <ContactForm @close-contact-popup="closeContactFormPopup()"/>
    </LandingPopup>

    <LandingPopup
      :is-open="isShownPrivacyPolicy"
      title="GoPlanr Privacy Policy"
      class="popup--policy"
      @close="isShownPrivacyPolicy = false"
    >
      <PrivacyPolicy/>
    </LandingPopup>

    <LandingPopup
      :is-open="areShownTerms"
      title="GoPlanr Terms and Conditions"
      class="popup--policy"
      @close="areShownTerms = false"
    >
      <TermsAndConditions/>
    </LandingPopup>

  </footer>
</template>

<script>
  import LandingPopup from './LandingPopup';
  import ContactForm from '../Form/ContactForm';
  import SocialLinks from '../Shared/SocialLinks';
  import PrivacyPolicy from '../PrivacyPolicy';
  import TermsAndConditions from '../TermsAndConditions';

  export default {
    components: {
      SocialLinks,
      LandingPopup,
      ContactForm,
      PrivacyPolicy,
      TermsAndConditions
    },
    data() {
      return {
        isShownContactForm: false,
        isShownPrivacyPolicy: false,
        areShownTerms: false,
        currentYear: new Date().getFullYear()
      }
    },
    methods: {
      closeContactFormPopup() {
        this.isShownContactForm = false;
      },
    }
  }
</script>

<style scoped>
  .footer__content {
    padding-top: 21px;
    padding-bottom: 15px;
    white-space: nowrap;

    @media (--viewport-tablet) {
      padding-bottom: 24px;
    }

    @media (--viewport-desktop) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .footer__item--social {
    display: flex;
    flex-direction: column;

    @media (--viewport-tablet) {
      flex-direction: row;
      align-items: center;
    }
  }

  .footer__box {
    @media (--viewport-tablet) {
      display: inline-block;
      width: 50%;
    }
  }

  .footer__logo {
    margin-bottom: 12px;
  }

  .footer__img {
    width: 112px;
    height: 32px;
  }

  .footer__navigation {
    margin-bottom: 25px;
  }

  .footer__link {
    display: inline-block;
    padding: 12px 12px 12px 0;
    font-size: 0.875rem;
    color: var(--text-color-secondary);
  }

  .footer__social {
    margin-bottom: 29px;

    @media (--viewport-tablet) {
      margin-bottom: 0;
    }
  }

  .footer__info {
    font-size: 0.75rem;
    color: var(--text-color-tertiary);

    @media (--viewport-tablet) {
      text-align: right;
    }
  }
</style>
