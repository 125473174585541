var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "privacy-policy-back-button" },
    [
      _c(
        "router-link",
        {
          staticClass: "privacy-policy-back-button__link",
          attrs: { to: { name: "landingHomePage" } },
        },
        [
          _c("svg", { staticClass: "privacy-policy-back-button__ico" }, [
            _c("use", { attrs: { href: "#back" } }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }