var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-tooltip" }, [
    _c("svg", { staticClass: "input-tooltip__ico" }, [
      _c("use", { attrs: { href: "#info" } }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "input-tooltip__text" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }