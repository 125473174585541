var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sidebar" }, [
    _c("div", { staticClass: "sidebar__section sidebar__section--header" }, [
      _c("div", { staticClass: "sidebar__wrapper wrapper" }, [
        _c("div", { staticClass: "sidebar__header" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "sidebar__box sidebar__box--login only-tablet-desktop",
            },
            [_c("LoginButton", { staticClass: "login--primary" })],
            1
          ),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "sidebar__box sidebar__box--button" },
            [_vm._t("default")],
            2
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "sidebar__section sidebar__section--content" }, [
      _c("div", { staticClass: "sidebar__wrapper wrapper" }, [
        _c("div", { staticClass: "sidebar__content" }, [
          _c(
            "nav",
            { staticClass: "sidebar__nav" },
            _vm._l(_vm.navLinks, function (navItem) {
              return _c("div", { staticClass: "sidebar__item" }, [
                _c(
                  "a",
                  {
                    staticClass: "sidebar__link",
                    attrs: { href: "#" + navItem.link },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close-sidebar")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(navItem.title) +
                        "\n              "
                    ),
                    _c("svg", { staticClass: "sidebar__nav-ico" }, [
                      _c("use", {
                        attrs: { href: "#arrow-right-circle-thin" },
                      }),
                    ]),
                  ]
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "sidebar__actions only-mobile" }, [
            _c(
              "a",
              {
                staticClass:
                  "sidebar__button button button--large button--stroke",
                attrs: { href: "/sign/login" },
              },
              [_vm._v("\n            Log In\n          ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "sidebar__button button button--large button--primary",
                on: {
                  click: function ($event) {
                    return _vm.gotoProviderRegistration()
                  },
                },
              },
              [_vm._v("\n            Start Free Month Trial\n          ")]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "sidebar__text" }, [
              _vm._v("No credit card required"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "sidebar__social only-tablet-desktop" },
            [_c("SocialLinks")],
            1
          ),
          _vm._v(" "),
          _c("img", {
            staticClass: "sidebar__img only-mobile",
            attrs: {
              src: "/images/landing/sidebar-img.png",
              alt: "sidebar-img",
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "sidebar__img only-tablet-desktop",
            attrs: {
              src: "/images/landing/sidebar-img-tablet.png",
              alt: "sidebar-img",
            },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sidebar__box sidebar__box--img" }, [
      _c("img", {
        staticClass: "sidebar__logo",
        attrs: { src: "/images/landing/gccs-logo.svg", alt: "goplanr-logo" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "sidebar__box sidebar__box--action only-tablet-desktop" },
      [
        _c(
          "button",
          {
            staticClass: "sidebar__button button button--primary button--small",
          },
          [_vm._v("Start Trial")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }