<template>
  <div class="sidebar">
    <div class="sidebar__section sidebar__section--header">
      <div class="sidebar__wrapper wrapper">
        <div class="sidebar__header">
          <div class="sidebar__box sidebar__box--img">
            <img class="sidebar__logo" src="/images/landing/gccs-logo.svg" alt="goplanr-logo">
          </div>

          <div class="sidebar__box sidebar__box--login only-tablet-desktop">
            <LoginButton class="login--primary"/>
          </div>

          <div class="sidebar__box sidebar__box--action only-tablet-desktop">
            <button class="sidebar__button button button--primary button--small">Start Trial</button>
          </div>
          <div class="sidebar__box sidebar__box--button">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>

    <div class="sidebar__section sidebar__section--content">
      <div class="sidebar__wrapper wrapper">
        <div class="sidebar__content">
          <nav class="sidebar__nav">
            <div v-for="navItem in navLinks" class="sidebar__item">
              <a
                class="sidebar__link"
                :href="'#' + navItem.link"
                @click="$emit('close-sidebar')"
              >
                {{ navItem.title }}
                <svg class="sidebar__nav-ico">
                  <use href="#arrow-right-circle-thin"></use>
                </svg>
              </a>
            </div>
          </nav>

          <div class="sidebar__actions only-mobile">
            <a
              class="sidebar__button button button--large button--stroke"
              href="/sign/login"
            >
              Log In
            </a>
            <button
              class="sidebar__button button button--large button--primary"
              @click="gotoProviderRegistration()"
            >
              Start Free Month Trial
            </button>
            <p class="sidebar__text">No credit card required</p>
          </div>

          <div class="sidebar__social only-tablet-desktop">
            <SocialLinks/>
          </div>

          <img class="sidebar__img only-mobile" src="/images/landing/sidebar-img.png" alt="sidebar-img">
          <img class="sidebar__img only-tablet-desktop" src="/images/landing/sidebar-img-tablet.png" alt="sidebar-img">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SocialLinks from '../Shared/SocialLinks.vue';
  import LoginButton from '../Shared/LoginButton.vue';

  export default {
    components: { SocialLinks, LoginButton },
    data() {
      return {
        navLinks: [
          {
            link: 'about',
            title: 'About'
          },
          {
            link: 'features',
            title: 'Features'
          },
          {
            link: 'advantages',
            title: 'Advantages'
          },
          {
            link: 'employees',
            title: 'Employees'
          },
          {
            link: 'pricing',
            title: 'Pricing'
          },
          {
            link: 'booking',
            title: 'Book a demo'
          }
        ],

      }
    },
    methods: {
      gotoProviderRegistration() {
        window.location.href = '/sign/security-provider/registration/choose-plan';
      }
    }
  }
</script>

<style scoped>
  .sidebar {
    transform: translateX(100%);
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    transition: .3s ease;
    background-color: var(--color-white);
    overflow: hidden;
    z-index: 999;
  }

  .sidebar--open {
    transform: translateX(0);
  }

  .sidebar__wrapper {
    height: 100%;
  }

  .sidebar__content {
    display: flex;
    flex-direction: column;
    padding-bottom: 39px;
    height: 100%;
    box-sizing: border-box;

    @media (--viewport-tablet) {
      position: relative;
    }
  }

  .sidebar__section--header {
    border-bottom: 1px solid var(--divider-color);
  }

  .sidebar__section--content {
    overflow: auto;
    height: calc(100% - var(--mobile-popup-header-height));
    box-sizing: border-box;
  }

  .sidebar__header {
    display: flex;
    align-items: center;
    height: var(--mobile-popup-header-height);
  }

  .sidebar__box--img {
    display: flex;
    flex-grow: 1;
  }

  .sidebar__box--login {
    padding-right: 16px;
  }

  .sidebar__box--button {
    flex-shrink: 0;
    height: 26px;
    width: 26px;
  }

  .sidebar__box--action {
    position: relative;
    flex-shrink: 0;
    padding-right: 44px;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      width: 1px;
      height: 32px;
      background-color: var(--divider-color);
    }
  }

  .sidebar__logo {
    width: 115px;
    height: 32px;
  }

  .sidebar__nav {
    flex-grow: 1;
    margin: 8px 0 32px 0;

    @media (--viewport-tablet) {
      margin: 40px 0 200px 0;
    }
  }

  .sidebar__item {
    padding: 14px 0;

    @media (--viewport-tablet) {
      position: relative;
      padding: 28px 0;
      border-bottom: 1px solid var(--primary-color-dark);
    }
  }

  .sidebar__link {
    font-size: 1.5rem;
    line-height: 1.16;
    color: var(--primary-color-dark);
    @media (--viewport-tablet) {
      display: block;
    }
  }

  .sidebar__nav-ico {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    fill: var(--primary-color-dark);

    @media (--viewport-tablet) {
      display: block;
    }
  }

  .sidebar__actions {
    text-align: center;
  }

  .sidebar__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 11px;

    @media (--viewport-tablet) {
      margin-bottom: 0;
      padding: 0 16px;
    }
  }

  .sidebar__text {
    font-size: 0.875rem;
    color: var(--text-color-tertiary);
  }

  .sidebar__img {
    position: absolute;
    width: 80px;
    height: 108px;
    bottom: -65px;
    right: 20px;

    @media (--viewport-tablet) {
      width: 140px;
      height: 144px;
      bottom: 0;
      right: -40px;
    }
  }

</style>
