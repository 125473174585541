var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "landing-employees" }, [
    _c(
      "div",
      {
        staticClass:
          "landing-employees__box landing-employees__box--img only-desktop",
      },
      [
        _c("div", { staticClass: "landing-employees__img-wrapper" }, [
          _c(
            "video",
            {
              staticClass: "landing-employees__img",
              attrs: {
                width: "288",
                height: "506",
                autoplay: "",
                loop: "",
                muted: "",
              },
              domProps: { muted: true },
            },
            [
              _c("source", {
                attrs: { src: "/videos/landing/app.mp4", type: "video/mp4" },
              }),
              _vm._v(
                "\n      Your browser does not support the video tag.\n    "
              ),
            ]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "landing-employees__box landing-employees__box--content" },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "observe-visibility",
                rawName: "v-observe-visibility",
                value: {
                  callback: (isVisible, entry) =>
                    _vm.isViewableNow(isVisible, entry, "header"),
                  once: true,
                },
                expression:
                  "{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, 'header'), once: true}",
              },
            ],
            staticClass: "landing-employees__header",
            class: { visible: _vm.showAnimation.header },
          },
          [
            _c("p", { staticClass: "landing-employees__title section-title" }, [
              _vm._v("Employees"),
            ]),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticClass:
                  "landing-employees__title landing-employees__title--secondary secondary-title",
              },
              [
                _vm._v(
                  "Bring shift allocation and staff interaction into the mobile age"
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "landing-employees__title landing-employees__title--subtitle text-secondary",
              },
              [
                _vm._v(
                  "Allow your employees to interact with you and their schedules using their smart phones."
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "landing-employees__img-wrapper only-mobile-tablet" },
          [
            _c(
              "video",
              {
                staticClass: "landing-employees__img",
                attrs: {
                  width: "288",
                  height: "506",
                  autoplay: "",
                  loop: "",
                  muted: "",
                  playsinline: "",
                },
                domProps: { muted: true },
              },
              [
                _c("source", {
                  attrs: { src: "/videos/landing/app.mp4", type: "video/mp4" },
                }),
                _vm._v(
                  "\n        Your browser does not support the video tag.\n      "
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "ul",
          {
            directives: [
              {
                name: "observe-visibility",
                rawName: "v-observe-visibility",
                value: {
                  callback: (isVisible, entry) =>
                    _vm.isViewableNow(isVisible, entry, "list"),
                  once: true,
                },
                expression:
                  "{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, 'list'), once: true}",
              },
            ],
            staticClass: "landing-employees__list",
            class: { visible: _vm.showAnimation.list },
          },
          [
            _c("p", { staticClass: "landing-employees__text" }, [
              _vm._v("Using the Staff portal and mobile apps your staff can:"),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "landing-employees__item" }, [
              _c("svg", { staticClass: "landing-employees__ico" }, [
                _c("use", { attrs: { href: "#checkmark" } }),
              ]),
              _vm._v("\n        View their schedule in real time\n      "),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "landing-employees__item" }, [
              _c("svg", { staticClass: "landing-employees__ico" }, [
                _c("use", { attrs: { href: "#checkmark" } }),
              ]),
              _vm._v("\n        Respond to shift invites\n      "),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "landing-employees__item" }, [
              _c("svg", { staticClass: "landing-employees__ico" }, [
                _c("use", { attrs: { href: "#checkmark" } }),
              ]),
              _vm._v("\n        Request time off and set availability\n      "),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "landing-employees__item" }, [
              _c("svg", { staticClass: "landing-employees__ico" }, [
                _c("use", { attrs: { href: "#checkmark" } }),
              ]),
              _vm._v("\n        View timesheets for payroll\n      "),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "landing-employees__item" }, [
              _c("svg", { staticClass: "landing-employees__ico" }, [
                _c("use", { attrs: { href: "#checkmark" } }),
              ]),
              _vm._v(
                "\n        Contractors / Self Employed can generate their invoices through their portal saving them and you time\n      "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "observe-visibility",
                rawName: "v-observe-visibility",
                value: {
                  callback: (isVisible, entry) =>
                    _vm.isViewableNow(isVisible, entry, "testimonial"),
                  once: true,
                },
                expression:
                  "{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, 'testimonial'), once: true}",
              },
            ],
            staticClass: "landing-employees__testimonial",
            class: { visible: _vm.showAnimation.testimonial },
          },
          [
            _c("LandingTestimonial", {
              attrs: {
                text: "“GoPlanr has allowed us to manage our staff efficiently across all our sites across the UK. Great system and intuitive to use.”",
                name: "Daniel Pitt",
                title: "National Account Manager at MAST",
                img: "mast-logo",
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }