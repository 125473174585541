var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pricing-counter",
      class: { "pricing-counter--disabled": _vm.isDisabled },
    },
    [
      _c("button", {
        staticClass: "pricing-counter__button",
        class: { "pricing-counter__button--disabled": _vm.isMinimumValue },
        attrs: { type: "button", disabled: _vm.isMinimumValue },
        on: { click: _vm.decreaseValue },
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "mask",
            rawName: "v-mask",
            value: "###",
            expression: "'###'",
          },
        ],
        staticClass: "pricing-counter__input",
        attrs: { type: "number" },
        domProps: { value: _vm.value },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("button", {
        staticClass: "pricing-counter__button pricing-counter__button--plus",
        attrs: { type: "button" },
        on: { click: _vm.increaseValue },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }