<template>
  <div class="success-message">
    <div class="success-message__item success-message__item--ico">
      <svg class="success-message__ico">
        <use xlink:href="#success"></use>
      </svg>
    </div>
    <div class="success-message__item success-message__item--title">
      <p class="success-message__title">{{ title }}</p>
    </div>

    <div class="success-message__item success-message__item--text">
      <p class="success-message__text text-secondary">{{ text }}</p>
    </div>

    <div class="success-message__item">
      <ButtonRippleEffect class="ripple-outer--light">
        <button
          type="button"
          class="success-message__button button button--small button--stroke"
          v-on:click="$emit('button-click')"
        >
          Got it
        </button>
      </ButtonRippleEffect>
    </div>
  </div>
</template>

<script>
  import ButtonRippleEffect from '../Shared/ButtonRippleEffect';
  export default {
    components: {ButtonRippleEffect},
    props: ['title', 'text']
  }
</script>

<style scoped>
  .success-message {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 40px 24px 0;
    box-sizing: border-box;
  }

  .success-message__item {
    display: flex;
    justify-content: center;
  }

  .success-message__item--ico {
    margin-bottom: 36px;
  }

  .success-message__item--title {
    margin-bottom: 11px;
  }

  .success-message__item--text {
    margin-bottom: 11px;
    flex-grow: 1;
  }

  .success-message__ico {
    width: 160px;
    height: 120px;
  }

  .success-message__title {
    font-size: 1.25rem;
    color: var(--text-color-primary);
  }

  .success-message__text {
    text-align: center;
  }
</style>
