<template>
  <div class="landing-testimonial">
    <blockquote class="landing-testimonial__quote">{{ text }}</blockquote>
    <div class="landing-testimonial__item">
      <div class="landing-testimonial__box">
        <img class="landing-testimonial__img" :src="'/images/landing/'+ img + '.png'" alt="user">
      </div>
      <div class="landing-testimonial__box">
        <p class="landing-testimonial__name">{{ name }}</p>
        <p class="landing-testimonial__title"> {{ title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['text', 'title', 'name', 'img'],

  }
</script>

<style scoped>
  .landing-testimonial {
    padding: 20px 24px;
    background-color: var(--light-background);
    border-radius: 2px;
    border: 1px solid var(--light-background);
    box-sizing: border-box;
  }

   .landing-testimonial--white {
     background-color: var(--color-white);
     border: 1px solid var(--divider-color);
   }

   .landing-testimonial--flex {

     @media (--viewport-desktop) {
       min-height: 268px;
       display: flex;
       flex-direction: column;

       .landing-testimonial__quote {
         flex-grow: 1;
       }

       .landing-testimonial__title {
         min-height: 32px;
       }
     }
   }

  .landing-testimonial__quote {
    margin-bottom: 14px;
    color: var(--text-color-secondary);
    font-size: 1.25rem;
    line-height: 1.4;
    font-style: italic;

    @media (--viewport-tablet) {
      margin-bottom: 30px;
    }
  }

  .landing-testimonial__item {
    display: flex;
    margin: 0 -4px;
  }

  .landing-testimonial__box {
    padding: 0 4px;
  }

  .landing-testimonial__img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 4px 12px rgba(19, 33, 74, 0.12);
    box-sizing: border-box;
  }

  .landing-testimonial__name {
    font-size: 1rem;
    line-height: 1.5;
  }

  .landing-testimonial__title {
    font-size: 0.875rem;
    color: var(--text-color-tertiary);
  }
</style>
