<template>
  <div class="content">
    <TheHeader v-if="!$route.meta.hideHeader"/>
    <router-view/>
    <BookingForm v-if="!$route.meta.hideBooking" id="booking" />
    <AppPromo/>
    <TheFooter/>
  </div>
</template>

<script>
  import TheFooter from './Components/Layout/TheFooter';
  import AppPromo from './Components/AppPromo/AppPromo';
  import BookingForm from './Components/Form/BookingForm';
  import TheHeader from './Components/Layout/TheHeader';

  export default {
    components: {
      TheHeader,
      BookingForm,
      AppPromo,
      TheFooter
    }
  };
</script>
