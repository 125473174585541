var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "header",
      staticClass: "header",
      class: {
        "header--sticky": _vm.hasStickyHeader,
        "header--remove-sticky": _vm.isRemovingStickyHeader,
      },
    },
    [
      _c(
        "div",
        { staticClass: "header__wrapper wrapper" },
        [
          _c("div", { staticClass: "header__content" }, [
            _c("div", { staticClass: "header__box header__box--logo" }, [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "scroll-to",
                      rawName: "v-scroll-to",
                      value: { element: "#app" },
                      expression: "{element: '#app'}",
                    },
                  ],
                  staticClass: "header__link",
                  attrs: { href: "#" },
                },
                [
                  _c("img", {
                    staticClass: "header__img",
                    attrs: {
                      src: "/images/landing/gccs-logo-white.svg",
                      alt: "goplanr-logo-white",
                    },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "header__img header__img--dark",
                    attrs: {
                      src: "/images/landing/gccs-logo.svg",
                      alt: "goplanr-logo",
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "header__box header__box--nav" }, [
              _c(
                "nav",
                { staticClass: "header__nav" },
                _vm._l(_vm.menuItems, function (menuItem) {
                  return _c("div", { staticClass: "header__item" }, [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "scroll-to",
                            rawName: "v-scroll-to",
                            value: {
                              element: menuItem.hash,
                              offset: menuItem.offset,
                            },
                            expression:
                              "{element: menuItem.hash, offset: menuItem.offset}",
                          },
                        ],
                        staticClass: "header__link",
                        class: {
                          "header__link--active": _vm.isActiveItem(
                            menuItem.hash
                          ),
                        },
                        attrs: { href: menuItem.hash },
                      },
                      [_vm._v(_vm._s(menuItem.title))]
                    ),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "header__box header__box--actions" }, [
              _c("div", { staticClass: "header__list" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "header__button-wrapper header__button-wrapper--login only-tablet-desktop",
                  },
                  [
                    _c("LoginButton", {
                      class: { "login--primary": _vm.hasStickyHeader },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.hasStickyHeader
                  ? _c(
                      "div",
                      { staticClass: "header__button-wrapper" },
                      [
                        _c("ButtonRippleEffect", [
                          _c(
                            "button",
                            {
                              staticClass:
                                "header__button button button--primary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.gotoProviderRegistration()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                Start Trial\n              "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "header__button-wrapper only-mobile-tablet" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "header__menu",
                        attrs: { type: "button" },
                        on: { click: _vm.toggleSidebar },
                      },
                      [_c("span", { staticClass: "header__bar" })]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "TheSidebar",
            {
              class: { "sidebar--open": _vm.sidebarOpen },
              on: { "close-sidebar": _vm.toggleSidebar },
            },
            [
              _c(
                "button",
                {
                  staticClass: "header__close",
                  attrs: { type: "button" },
                  on: { click: _vm.toggleSidebar },
                },
                [
                  _c("span", { staticClass: "header__close-wrapper" }, [
                    _c("svg", { staticClass: "header__close-ico" }, [
                      _c("use", { attrs: { href: "#close" } }),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }