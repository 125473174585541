import axios from 'axios';
import humps from 'humps';

export default axios.create({
  baseURL: '/api',
  transformResponse: [
    ...axios.defaults.transformResponse,
    data => humps.camelizeKeys(data)
  ],
  transformRequest: [
    data => humps.decamelizeKeys(data),
    ...axios.defaults.transformRequest
  ],
  headers: {
    'X-Session-Id': {
      toString () {
        return localStorage.getItem('sessionId');
      }
    }
  }
});
