var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "landing" }, [
    _c(
      "section",
      { staticClass: "landing__section landing__section--hero" },
      [_c("LandingHero")],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "landing__section", attrs: { id: "about" } },
      [_c("LandingAbout")],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      {
        staticClass: "landing__section landing__section-features",
        attrs: { id: "features" },
      },
      [
        _c(
          "div",
          {
            staticClass: "landing__wrapper landing__wrapper--features wrapper",
          },
          [_c("LandingFeatures")],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "section",
      {
        staticClass: "landing__section landing__section--advantages",
        attrs: { id: "advantages" },
      },
      [_c("LandingAdvantages")],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "landing__section landing__section--cta" },
      [_c("LandingCta")],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      {
        staticClass: "landing__section landing__section--employees",
        attrs: { id: "employees" },
      },
      [
        _c(
          "div",
          {
            staticClass: "landing__wrapper landing__wrapper--employees wrapper",
          },
          [_c("LandingEmployees")],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "landing__section landing__section--app-description" },
      [_c("LandingAppDescription")],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "landing__section", attrs: { id: "pricing" } },
      [_c("LandingPricing")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }