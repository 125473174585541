import LandingLayout from '../LandingLayout';
import LandingHomePage from '../Pages/LandingHome';
import LandingPricingPage from '../Pages/LandingPricing';
import LandingPrivacyPolicyPage from '../Pages/LandingPrivacyPolicy';

export const LANDING_ROUTER = {
  path: '/',
  component: LandingLayout,
  children: [{
    path: '',
    name: 'landingHomePage',
    component: LandingHomePage
  }, {
    path: 'pricing',
    name: 'landingPricingPage',
    component: LandingPricingPage,
    meta: { hideHeader: true }
  }, {
    path: 'privacy-policy',
    name: 'landingPrivacyPolicyPage',
    component: LandingPrivacyPolicyPage,
    meta: { hideHeader: true, hideBooking: true }
  }]
};
