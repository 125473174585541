var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    { staticClass: "footer" },
    [
      _c("div", { staticClass: "footer__wrapper wrapper" }, [
        _c("div", { staticClass: "footer__content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "footer__item" }, [
            _c("nav", { staticClass: "footer__navigation" }, [
              _c("div", { staticClass: "footer__item" }, [
                _c(
                  "a",
                  {
                    staticClass: "footer__link",
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        _vm.isShownPrivacyPolicy = true
                      },
                    },
                  },
                  [_vm._v("Privacy Policy")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "footer__item" }, [
                _c(
                  "a",
                  {
                    staticClass: "footer__link",
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        _vm.areShownTerms = true
                      },
                    },
                  },
                  [_vm._v("Terms & Conditions")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "footer__item" }, [
                _c(
                  "a",
                  {
                    staticClass: "footer__link",
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        _vm.isShownContactForm = true
                      },
                    },
                  },
                  [_vm._v("Contact Us")]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "footer__item footer__item--social" }, [
            _c("div", { staticClass: "footer__box" }, [
              _c(
                "div",
                { staticClass: "footer__social" },
                [_c("SocialLinks")],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "footer__box" }, [
              _c("div", { staticClass: "footer__info" }, [
                _vm._v(
                  "© GoPlanr " +
                    _vm._s(_vm.currentYear) +
                    ". All rights reserved"
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "LandingPopup",
        {
          attrs: { "is-open": _vm.isShownContactForm, title: "Contact us" },
          on: {
            close: function ($event) {
              return _vm.closeContactFormPopup()
            },
          },
        },
        [
          _c("ContactForm", {
            on: {
              "close-contact-popup": function ($event) {
                return _vm.closeContactFormPopup()
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "LandingPopup",
        {
          staticClass: "popup--policy",
          attrs: {
            "is-open": _vm.isShownPrivacyPolicy,
            title: "GoPlanr Privacy Policy",
          },
          on: {
            close: function ($event) {
              _vm.isShownPrivacyPolicy = false
            },
          },
        },
        [_c("PrivacyPolicy")],
        1
      ),
      _vm._v(" "),
      _c(
        "LandingPopup",
        {
          staticClass: "popup--policy",
          attrs: {
            "is-open": _vm.areShownTerms,
            title: "GoPlanr Terms and Conditions",
          },
          on: {
            close: function ($event) {
              _vm.areShownTerms = false
            },
          },
        },
        [_c("TermsAndConditions")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer__item" }, [
      _c("div", { staticClass: "footer__logo" }, [
        _c("img", {
          staticClass: "footer__img",
          attrs: { src: "/images/landing/gccs-logo.svg", alt: "goplanr-logo" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }