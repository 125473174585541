var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pricing-table" },
    [
      _c(
        "div",
        { staticClass: "pricing-table__item pricing-table__item--header" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "pricing-table__box" }, [
            _c(
              "p",
              {
                staticClass:
                  "pricing-table__title pricing-table__title--pro only-mobile-tablet",
              },
              [
                _vm._v("\n        Pro\n        "),
                _c("svg", { staticClass: "pricing-table__title-ico" }, [
                  _c("use", { attrs: { href: "#star" } }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "pricing-table__title pricing-table__title--pro only-desktop",
              },
              [
                _vm._v("\n        Professional\n        "),
                _c("svg", { staticClass: "pricing-table__title-ico" }, [
                  _c("use", { attrs: { href: "#star" } }),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.features, function (feature) {
        return _c(
          "div",
          { staticClass: "pricing-table__item" },
          [
            _c(
              "div",
              { staticClass: "pricing-table__box pricing-table__box--feature" },
              [
                _c("span", { staticClass: "pricing-table__name" }, [
                  _vm._v(
                    "\n        " + _vm._s(feature.featureTitle) + "\n      "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm._l(feature.isInPlan, function (isInPlan) {
              return _c("div", { staticClass: "pricing-table__box" }, [
                isInPlan
                  ? _c("span", { staticClass: "pricing-table__ico-wrapper" }, [
                      _c("svg", { staticClass: "pricing-table__ico" }, [
                        _c("use", { attrs: { href: "#checkmark" } }),
                      ]),
                    ])
                  : _c(
                      "span",
                      {
                        staticClass:
                          "pricing-table__ico-wrapper pricing-table__ico-wrapper--none",
                      },
                      [
                        _c("svg", { staticClass: "pricing-table__ico" }, [
                          _c("use", { attrs: { href: "#close" } }),
                        ]),
                      ]
                    ),
              ])
            }),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "pricing-table__box pricing-table__box--feature" },
      [
        _c(
          "p",
          { staticClass: "pricing-table__title pricing-table__title--feature" },
          [_vm._v("\n        Feature\n      ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing-table__box" }, [
      _c("p", { staticClass: "pricing-table__title" }, [
        _vm._v("\n        Starter\n      "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing-table__box" }, [
      _c("p", { staticClass: "pricing-table__title" }, [
        _vm._v("\n        Basic\n      "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }