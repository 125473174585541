<template>
  <div class="landing-advantages">
    <div class="landing-advantages__wrapper wrapper">
      <div class="landing-advantages__content">
        <div class="landing-advantages__header"
             v-observe-visibility="{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, 'a'), once: true}"
             :class="{'visible': showAnimation.a }"
        >
          <p class="landing-advantages__title section-title">ADVANTAGES</p>
          <h2 class="landing-advantages__title landing-advantages__title--secondary secondary-title">Win clients by giving them the transparency they crave</h2>
          <p class="landing-advantages__title landing-advantages__title--subtitle text-secondary">Your clients always want to know who they have working on their sites and GoPlanr allows you to give them access so they can view their schedule in real time.</p>
        </div>
        <ul class="landing-advantages__list"
            v-observe-visibility="{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, 'b'), once: true}"
            :class="{'visible': showAnimation.b}"
        >

          <li class="landing-advantages__item" >
            <div class="landing-advantages__data">
              <svg class="landing-advantages__ico">
                <use href="#advantages-portal"></use>
              </svg>
              <p class="landing-advantages__name"> Client Portal</p>
              <p class="landing-advantages__text text-secondary"> Access to Client Portal in order to assist clients needs </p>

            </div>
          </li>

          <li class="landing-advantages__item">
            <div class="landing-advantages__data">
              <svg class="landing-advantages__ico">
                <use href="#advantages-shift"></use>
              </svg>
              <p class="landing-advantages__name"> Real-time schedule</p>
              <p class="landing-advantages__text text-secondary"> View real-time schedule to see who is working on clients sites </p>

            </div>
          </li>

          <li class="landing-advantages__item">
            <div class="landing-advantages__data">
              <svg class="landing-advantages__ico">
                <use href="#advantages-reports"></use>
              </svg>
              <p class="landing-advantages__name">Detailed financial and HR reports</p>
              <p class="landing-advantages__text text-secondary"> Increase transparency and client engagement in the service you provide </p>

            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        showAnimation: {
          a: false,
          b: false
        }
      }
    },
    methods: {
      isViewableNow(isVisible, entry, section) {
        this.showAnimation[section] = isVisible;
      }
    }

  }
</script>

<style scoped>

  .landing-advantages {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: var(--light-background);

    @media (--viewport-tablet) {
      padding-top: 35px;
      padding-bottom: 80px;
    }

    @media (--viewport-desktop) {
      padding-bottom: 120px;
    }
  }

  .landing-advantages__title {
    text-align: center;

    @media (--viewport-desktop) {
      margin: 0 auto 11px;
      max-width: 632px;
    }
  }

  .landing-advantages__title--secondary {
    margin-bottom: 13px;
  }

  .landing-advantages__title--subtitle {
    margin-bottom: 37px;

    @media (--viewport-tablet) {
      margin-bottom: 45px;
    }
  }

  .landing-advantages__list {
    @media (--viewport-tablet) {
      margin: 0 -8px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    @media (--viewport-desktop) {
      margin: 0 -16px;
    }
  }

  .landing-advantages__item {
    margin-bottom: 20px;
    width: 100%;

    @media (--viewport-tablet) {
      margin-bottom: 16px;
      padding: 0 8px;
      width: 50%;
      box-sizing: border-box;
    }

    @media (--viewport-desktop) {
      padding: 0 16px;
      width: 33.33%;
    }
  }

  .landing-advantages__data {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 20px 20px;
    width: 100%;
    height: 100%;
    min-height: 312px;
    background-color: var(--color-white);
    border-radius: 2px;
    box-sizing: border-box;

    @media (--viewport-desktop) {
      min-height: 360px;
    }
  }



  .landing-advantages__ico {
    margin-bottom: 36px;
    width: 80px;
    height: 80px;
  }

  .landing-advantages__name {
    margin-bottom: 11px;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .landing-advantages__text {
    text-align: center;
    max-width: 228px;
  }
</style>
