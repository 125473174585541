var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "landing-about" }, [
    _c("div", { staticClass: "landing-about__wrapper wrapper" }, [
      _c("div", { staticClass: "landing-about__content" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "landing-about__box landing-about__box--content" },
          [
            _c(
              "p",
              {
                staticClass:
                  "landing-about__title landing-about__title section-title only-desktop",
              },
              [_vm._v("about")]
            ),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticClass:
                  "landing-about__title landing-about__title--secondary secondary-title",
              },
              [
                _vm._v(
                  "GoPlanr keeps all your employee management activities in the cloud"
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "landing-about__title landing-about__title--subtitle text-primary",
              },
              [
                _vm._v(
                  "GoPlanr is a unified software solution, that allows you to easily automate, organise and structure all your staff management operations in one place."
                ),
              ]
            ),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "landing-about__testimonial" },
              [
                _c("LandingTestimonial", {
                  staticClass: "landing-testimonial--white",
                  attrs: {
                    text: "“GoPlanr helped us to streamline our operations and back office activities giving us more time to concentrate on our clients and the service we provide.”",
                    name: "Denes Doman",
                    title: "Head of Operations at GC Covert Security",
                    img: "gccs-logo",
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "landing-about__box only-desktop" }, [
      _c("div", { staticClass: "landing-about__img-wrapper" }, [
        _c("img", {
          staticClass: "landing-about__img",
          attrs: { src: "/images/landing/about.png", alt: "about-img" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "landing-about__img-wrapper only-mobile-tablet" },
      [
        _c("img", {
          staticClass: "landing-about__img",
          attrs: { src: "/images/landing/about.png", alt: "about-img" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }