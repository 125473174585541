<template>
  <div class="popup" :class="{ 'popup--open': isOpen }">
    <i class="popup__overlay"/>
    <div class="popup__content"
         v-body-click="{ closeCb: closePopup, condition: isOpen }"
    >
      <button
        type="button"
        class="popup__close"
        @click="closePopup()"
      >
        <svg class="popup__ico"><use href="#close"></use></svg>
      </button>
      <div class="popup__header" v-if="title">
        <p class="popup__title">{{ title }}</p>
      </div>
      <div class="popup__data" :class="{'popup__data--no-header' : !title}">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['title', 'isOpen'],
    methods: {
      closePopup() {
        this.$emit('close');
      }
    },
    watch: {
      isOpen: function(val) {
        document.body.classList.toggle('popup-open', val);
      }
    }
  }
</script>

<style scoped>
  .popup {
    align-items: center;
    justify-content: center;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    box-sizing: border-box;
  }

  .popup--open {
    display: flex;
  }

  .popup--policy {
    .popup__content {

      @media (--viewport-tablet) {
        height: 77%;
        max-width: 781px;
      }

      @media (--viewport-desktop) {
        height: 80%;
      }
    }

    .popup__header {
      padding: 16px 24px;
      justify-content: flex-start;
    }

    .popup__title {
      font-weight: normal;
    }

    .popup__data {
      padding: 15px 40px 30px 24px;
      @media (--viewport-tablet) {
        padding: 15px 56px 30px 24px;
      }
    }
  }

  .popup__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
  }

  .popup__content {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    box-shadow: 0 0 72px rgba(19, 33, 74, 0.02);
    box-sizing: border-box;

    @media (--viewport-tablet) {
      max-width: 608px;
      height: 498px;
      box-shadow: 0 0 72px rgba(19, 33, 74, 0.02);
      border-radius: 4px;
      border: 1px solid var(--divider-color);
    }
  }

  .popup__header {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: var(--mobile-popup-header-height);
    position: relative;
    padding: 16px;
    border-bottom: 1px solid var(--divider-color);
    box-sizing: border-box;

    @media (--viewport-tablet) {
      padding: 26px;
      height: var(--tablet-popup-header-height);
    }

  }

  .popup__title {
    max-width: 80%;
    font-size: 1.25rem;
    color: var(--text-color-primary);
    font-weight: 500;
    line-height: 1.2;
  }

  .popup__close {
    position: absolute;
    top: 18px;
    right: 16px;
    width: 24px;
    height: 24px;
    z-index: 999;

    @media (--viewport-tablet) {
      top: 28px;
      right: 24px;
    }

  }
  .popup__ico {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 12px;
    height: 12px;
    fill: var(--text-color-tertiary);
  }

  .popup__data {
    padding: 20px 24px 80px;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    height: calc(100% - var(--mobile-popup-header-height));
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background:#9EAABA;
      border-radius: 2px;
    }

    @media (--viewport-tablet) {
      padding: 39px 40px 80px;
      height: calc(100% - var(--tablet-popup-header-height));
    }

  }

  .popup__data--no-header {
    height: 100%;
  }
</style>
