<template>
  <div class="landing-features">
    <div class="landing-features__header"
         v-observe-visibility="{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, '0'), once: true}"
         :class="{'visible': showAnimation[0] }"
    >
      <p class="landing-features__title section-title">Features</p>
      <h2 class="landing-features__title landing-features__title--secondary secondary-title">Transfer your operations to cloud</h2>
      <p class="landing-features__title landing-features__title--subtitle text-secondary">GoPlanr is a feature rich platform allowing you to run your agency in real time in the cloud. Access and manage your operations at any time from anywhere in the world.</p>
    </div>

    <div class="landing-features__list">
      <div
        v-for="feature in features" class="landing-features__item"
        v-observe-visibility="{callback: (isVisible, entry) => isViewableNow(isVisible, entry, feature.id), once: true}"
        :class="{'visible': showAnimation[feature.id] }"
      >
        <FeatureItem v-bind:featureDetails="feature"/>
      </div>
    </div>
  </div>
</template>

<script>
  import FeatureItem from '../FeatureItem.vue';
  export default {
    components: {FeatureItem},
    data() {
      return {
        showAnimation: {
          0: false,
          1: false,
          2: false,
          3: false
        },
        features: [
          {
            id: 1,
            sectionName: 'scheduling',
            title: 'Schedule your workforce',
            ico: 'calendar',
            img: 'scheduling',
            featureList: [
              'Plan employee workload with Booking & Employee timelines',
              'Manage multiple Locations & Clients',
              'Easily identify problems & find solutions'
            ]
          },
          {
            id: 2,
            sectionName: 'HUMAN RESOURCES',
            title: 'Manage human resources efficiently',
            ico: 'people',
            img: 'hr',
            isImgLeft: true,
            featureList: [
              'Host and maintain all your employee data securely',
              'Track and maintain licence and training records',
              'Manage time off, holidays, sick days and shift blow outs',
              'Manage pay rates across clients and job types',
              'Communicate with employees via the platform',
              'Manage employees with Company ID Card Manager',
              'Run and export HR reports on staff',
            ]
          },
          {
            id: 3,
            sectionName: 'Finances and payroll',
            title: 'Control financial flow',
            ico: 'payment',
            img: 'finances',
            featureList: [
              'Easily create and send client invoices with a few clicks',
              'Manage timesheets & payroll for employed staff',
              'Manage contractor invoices sent via the employee portal',
              'Create employed and contractor payruns and export to your payment processor',
              'Run and export detailed financial reports',
            ]
          }
        ]
      }
    },
    methods: {
      isViewableNow(isVisible, entry, section) {
        this.showAnimation[section] = isVisible;
      }
    }

  }
</script>

<style scoped>
  .landing-features {
    opacity: 1;
  }

  .landing-features__header {
    @media (--viewport-tablet) {
      margin: 0 auto;
      max-width: 512px;
    }

    @media (--viewport-tablet) {
      max-width: 632px;
    }
  }

  .landing-features__title {
    text-align: center;
  }

  .landing-features__title--secondary {
    margin-bottom: 16px;
  }

  .landing-features__title--subtitle {
    margin-bottom: 45px;
  }

  .landing-features__item {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  ;
  }
</style>
