<template>
  <div class="pricing">
    <PricingBackButton/>
    <div class="pricing__section pricing__section--header">
      <div class="pricing__wrapper wrapper">
        <PricingHeader/>
      </div>
    </div>
    <div class="pricing__section pricing__section--list">
      <div class="pricing__wrapper wrapper">
        <PricingList/>
      </div>
    </div>
    <div class="pricing__section pricing__section--table">
      <div class="pricing__wrapper pricing__wrapper--table wrapper">
        <PricingTable/>
      </div>
    </div>
    <div class="pricing__section pricing__section--testimonials">
      <div class="pricing__wrapper pricing__wrapper--testimonials wrapper">
        <PricingTestimonials/>
      </div>
    </div>
  </div>
</template>

<script>
  import PricingBackButton from '../Components/Pricing/PricingBackButton.vue';
  import PricingHeader from '../Components/Pricing/PricingHeader.vue';
  import PricingList from '../Components/Pricing/PricingList.vue';
  import PricingTable from '../Components/Pricing/PricingTable.vue';
  import PricingTestimonials from "../Components/Pricing/PricingTestimonials";

  export default {
    components: {
      PricingBackButton,
      PricingTestimonials,
      PricingHeader,
      PricingList,
      PricingTable
    }
  }
</script>

<style scoped>
  .pricing__wrapper {
    max-width: 1160px;
  }
  .pricing__section--header {
    margin-bottom: 40px;

    @media (--viewport-desktop) {
      margin-bottom: 62px;
    }
  }

  .pricing__section--table {
    margin-bottom: 40px;

    @media (--viewport-desktop) {
      margin-bottom: 80px;
    }
  }

  .pricing__section--testimonials {
    margin-bottom: 35px;

    @media (--viewport-tablet) {
      margin-bottom: 70px;
    }

    @media (--viewport-desktop) {
      margin-bottom: 115px;
    }
  }

  .pricing__wrapper--table {
    padding: 0;

    @media (--viewport-tablet) {
      padding: 0 40px;
    }

    @media (--viewport-desktop) {
      padding: 0 20px;
    }
  }

  .pricing__section--list {
    padding-bottom: 42px;
    @media (--viewport-tablet) {
      position: relative;
      background-color: var(--light-background);

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 64px;
        background-color: var(--color-white);
        z-index: 0;
      }
    }

    @media (--viewport-desktop) {
      background-color: transparent;
      &:after {
        content: none;
      }
    }
  }

</style>
