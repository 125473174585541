<template>
  <div class="pricing-table">
    <div class="pricing-table__item pricing-table__item--header">
      <div class="pricing-table__box pricing-table__box--feature">
        <p class="pricing-table__title pricing-table__title--feature ">
          Feature
        </p>
      </div>
      <div class="pricing-table__box">
        <p class="pricing-table__title">
          Starter
        </p>
      </div>
      <div class="pricing-table__box">
        <p class="pricing-table__title">
          Basic
        </p>
      </div>
      <div class="pricing-table__box">
        <p class="pricing-table__title pricing-table__title--pro only-mobile-tablet">
          Pro
          <svg class="pricing-table__title-ico">
            <use href="#star"/>
          </svg>
        </p>

        <p class="pricing-table__title pricing-table__title--pro only-desktop">
          Professional
          <svg class="pricing-table__title-ico">
            <use href="#star"/>
          </svg>
        </p>

      </div>
    </div>

    <div v-for="feature in features" class="pricing-table__item">
      <div class="pricing-table__box pricing-table__box--feature">
        <span class="pricing-table__name ">
          {{ feature.featureTitle }}
        </span>
      </div>

      <div v-for="isInPlan in feature.isInPlan" class="pricing-table__box">
        <span v-if="isInPlan" class="pricing-table__ico-wrapper">
          <svg class="pricing-table__ico">
            <use href="#checkmark"/>
          </svg>
        </span>

        <span v-else class="pricing-table__ico-wrapper pricing-table__ico-wrapper--none">
          <svg class="pricing-table__ico">
            <use href="#close"/>
          </svg>
        </span>
      </div>

    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        features: [{
          featureTitle: 'Staff Portal',
          isInPlan: [true, true, true]
        }, {
          featureTitle: 'Staff iOS App',
          isInPlan: [true, true, true]
        }, {
          featureTitle: 'Staff Android App',
          isInPlan: [true, true, true]
        }, {
          featureTitle: 'Client portal',
          isInPlan: [false, false, true]
        }, {
          featureTitle: 'Notifications',
          isInPlan: [true, true, true]
        }, {
          featureTitle: 'Schedule manager',
          isInPlan: [true, true, true]
        }, {
          featureTitle: 'Booking Timeline',
          isInPlan: [true, true, true]
        }, {
          featureTitle: 'Employee Timeline',
          isInPlan: [true, true, true]
        }, {
          featureTitle: 'Day Off/Holiday Manager',
          isInPlan: [true, true, true]
        }, {
          featureTitle: 'Silent Mode',
          isInPlan: [true, true, true]
        }, {
          featureTitle: 'Planning Mode',
          isInPlan: [false, false, true]
        }, {
          featureTitle: 'Booking Creator',
          isInPlan: [true, true, true]
        }, {
          featureTitle: 'Booking Templates',
          isInPlan: [true, true, true]
        }, {
          featureTitle: 'Staff ID Card Manager',
          isInPlan: [false, false, true]
        }, {
          featureTitle: 'Staff Email Manager',
          isInPlan: [false, false, true]
        }, {
          featureTitle: 'Home Zones',
          isInPlan: [false, false, true]
        }, {
          featureTitle: 'Client Invoice Manager',
          isInPlan: [false, false, true]
        }, {
          featureTitle: 'Payroll Manager',
          isInPlan: [false, false, true]
        }, {
          featureTitle: 'Contractor Invoice Manager',
          isInPlan: [false, false, true]
        }, {
          featureTitle: 'Booking/Shift Change Log',
          isInPlan: [false, false, true]
        }, {
          featureTitle: 'Staff Clock In/Out',
          isInPlan: [false, false, true]
        }]
      }
    }
  }
</script>

<style scoped>
  .pricing-table__item {
    display: flex;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
    background-color: var(--color-white);
    &:nth-child(even) {
      background-color: var(--light-background);
    }
  }

  .pricing-table__item--header {
    position: sticky;
    top: 0;
    z-index: 2;

    @media (--viewport-desktop) {
      position: static;
    }

    .pricing-table__box {
      padding: 8px;
      background-color: var(--color-white);
    }
  }

  .pricing-table__box {
    flex-basis: 60px;
    flex-grow: 1;
    min-height: 48px;
    max-width: 136px;
    padding: 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    &:first-child {
      padding-left: 0;
      padding-right: 20px;
    }

    @media (--viewport-desktop) {
      max-width: 212px;
    }
  }

  .pricing-table__box--feature {
    flex-basis: 160px;
    max-width: none;
    vertical-align: middle;
    justify-content: flex-start;
  }

  .pricing-table__title {
    font-size: 0.75rem;
    line-height: 2;
    color: var(--text-color-secondary);
    text-align: center;

    @media (--viewport-tablet) {
      font-size: 1rem;
    }
  }

  .pricing-table__title--feature {
    color: var(--text-color-tertiary);
    text-align: left;
  }

  .pricing-table__title--pro {
    color: var(--primary-color);
  }

  .pricing-table__title-ico {
    width: 8px;
    height: 8px;
    fill: var(--primary-color);
    vertical-align: text-top;

    @media (--viewport-desktop) {
      width: 12px;
      height: 12px;
    }
  }

  .pricing-table__ico-wrapper {
    position: relative;
    width: 16px;
    height: 16px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: 20px;
      height: 20px;
      background-color: var(--primary-color);
      border-radius: 50%;
    }
  }

  .pricing-table__ico {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 1;
    width: 16px;
    height: 16px;
    fill: var(--color-white);
  }

  .pricing-table__ico-wrapper--none {
    &:after {
      content: none;
    }

    .pricing-table__ico {
      width: 12px;
      height: 12px;
      fill: var(--text-color-tertiary);
      opacity: 0.5;
    }
  }

  .pricing-table__name {
    font-size: 0.875rem;
    color: var(--text-color-primary);
    line-height: 1.06;

    @media (--viewport-tablet) {
      font-size: 1rem;
    }
  }
</style>
