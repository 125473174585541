<template>
  <div class="landing">
    <section class="landing__section landing__section--hero">
      <LandingHero/>
    </section>

    <section class="landing__section" id="about">
      <LandingAbout/>
    </section>

    <section class="landing__section landing__section-features" id="features">
      <div class="landing__wrapper landing__wrapper--features wrapper">
        <LandingFeatures/>
      </div>
    </section>

    <section class="landing__section landing__section--advantages" id="advantages">
      <LandingAdvantages/>
    </section>

    <section class="landing__section landing__section--cta">
      <LandingCta/>
    </section>

    <section class="landing__section landing__section--employees" id="employees">
      <div class="landing__wrapper landing__wrapper--employees wrapper">
        <LandingEmployees/>
      </div>
    </section>

    <section class="landing__section landing__section--app-description">
      <LandingAppDescription/>
    </section>

    <section class="landing__section" id="pricing">
      <LandingPricing/>
    </section>
  </div>
</template>

<script>
  import LandingHero from '../Components/Home/LandingHero.vue';
  import LandingAbout from '../Components/Home/LandingAbout.vue';
  import LandingFeatures from '../Components/Home/LandingFeatures.vue';
  import LandingAdvantages from '../Components/Home/LandingAdvantages.vue';
  import LandingCta from '../Components/Home/LandingCta.vue';
  import LandingEmployees from '../Components/Home/LandingEmployees';
  import LandingAppDescription from '../Components/Home/LandingAppDescription';
  import LandingPricing from '../Components/Home/LandingPricing';
  import BookingForm from '../Components/Form/BookingForm';

  export default {
    components: {
      LandingHero,
      LandingAbout,
      LandingFeatures,
      LandingAdvantages,
      LandingCta,
      LandingEmployees,
      LandingAppDescription,
      LandingPricing,
      BookingForm
    }
  };
</script>

<style>
  .landing__section {
    margin-bottom: 35px;

    @media (--viewport-tablet) {
      margin-bottom: 75px;
    }

    @media (--viewport-desktop) {
      margin-bottom: 115px;
    }
  }

  .landing__section--hero {
    margin-bottom: 0;
  }

  .landing__wrapper--features {
    @media (--viewport-tablet) {
      max-width: 672px;
    }

    @media (--viewport-desktop) {
      max-width: 1160px;
    }
  }

  .landing__section--advantages {
    margin-bottom: 0;
  }

  .landing__section--employees {
    margin-bottom: 40px;

    @media (--viewport-tablet) {
      margin-bottom: 75px;
    }

    @media (--viewport-desktop) {
      margin-bottom: 115px;
    }
  }

  .landing__section--app-description {
    @media (--viewport-tablet) {
      margin-bottom: 38px;
    }
  }

  .landing__wrapper--employees {

    @media (--viewport-tablet) {
      max-width: 552px;
    }

    @media (--viewport-desktop) {
      padding-right: 115px;
      max-width: 1160px;
    }
  }
</style>
