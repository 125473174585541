<template>
  <div class="booking-form">
    <div class="booking-form__wrapper wrapper">
      <div class="booking-form__header"
           v-observe-visibility="{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, 'header'), once: true}"
           :class="{'visible': showAnimation.header}"
      >
        <p class="booking-form__title booking-form__title--section section-title">HAVING QUESTIONS?</p>
        <h2 class="booking-form__title booking-form__title--secondary secondary-title">Book a demo</h2>
        <p class="booking-form__title booking-form__title--subtitle text-primary">
          Please contact us below for a free demo, or if you have any questions or requests concerning our services.
        </p>
      </div>

      <ValidationObserver
        v-observe-visibility="{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, 'form'), once: true}"
        :class="{'visible': showAnimation.form}"
        v-if="!isShownSuccess"
        v-slot="{ handleSubmit }"
        slim
      >
        <form class="booking-form__section" @submit.prevent="handleSubmit(bookRequest)" novalidate>
          <div class="booking-form__item">
            <FormInput
              label="Name"
              placeholder="Enter your name"
              v-model="bookingData.name"
              validation-rules="required"
            />
          </div>

          <div class="booking-form__item">
            <FormInput
              label="Email"
              placeholder="Enter your email"
              input-type="email"
              v-model="bookingData.email"
              validation-rules="required|email"
            />
          </div>

          <div class="booking-form__item">
            <FormPhoneInput
              v-model="bookingData.phoneNumber"
              validation-rules="required"
            />
          </div>

          <div class="booking-form__item booking-form__item--options">
            <span class="booking-form__text">I am an:</span>
            <div class="booking-form__options">
              <SegmentedControl
                name="employmentType"
                v-model="bookingData.employmentType"
                :options="[{ id: 'employer', text: 'Employer' }, { id: 'employee', text: 'Employee' }]"
              />
            </div>
          </div>

          <div class="booking-form__item booking-form__item--button">
            <div class="booking-form__button-wrapper">
              <ButtonRippleEffect>
                <button type="submit" class="booking-form__button button button--primary button--large">Book a demo</button>
              </ButtonRippleEffect>
            </div>
            <div class="booking-form__notice">By submitting this form you agree to our terms. See our
              <a href="javascript:void(0);" class="booking-form__link" @click="isShownPrivacyPolicy = true">privacy policy</a>
              to learn about how we use your information.
            </div>
          </div>

        </form>
      </ValidationObserver>
    </div>

    <LandingPopup :is-open="isShownSuccess" @close="closeSuccessPopup()">
      <SuccessMessage
        title="Demo request sent"
        :text="bookingData.name + ', thank you for your interest in Goplanr. We will get back to you as soon as possible'"
        @button-click="closeSuccessPopup()"
      />
    </LandingPopup>

    <LandingPopup
      class="popup--policy"
      title="GoPlanr Privacy Policy"
      :is-open="isShownPrivacyPolicy"
      @close="isShownPrivacyPolicy = false"
    >
      <PrivacyPolicy/>
    </LandingPopup>

  </div>
</template>

<script>
  import FormInput from './Input/FormInput';
  import FormPhoneInput from './Input/FormPhoneInput';
  import SegmentedControl from './Input/SegmentedControl';
  import SuccessMessage from './SuccessMessage';
  import LandingPopup from '../Layout/LandingPopup';
  import ButtonRippleEffect from '../Shared/ButtonRippleEffect';
  import PrivacyPolicy from '../PrivacyPolicy';

  import axios from '@/core/Services/Axios';

  export default {
    components: {
      FormInput,
      FormPhoneInput,
      SegmentedControl,
      SuccessMessage,
      LandingPopup,
      ButtonRippleEffect,
      PrivacyPolicy
    },
    data() {
      return {
        isShownSuccess: false,
        isShownPrivacyPolicy: false,
        bookingData: {},
        showAnimation: {
          header: false,
          form: false
        }
      };
    },
    created() {
      this.resetBookingData();
    },
    methods: {
      bookRequest() {
        axios.post('/landing/demo_request', { demo_request: this.bookingData });
        this.isShownSuccess = true;
      },
      resetBookingData() {
        this.bookingData = {
          name: '',
          email: '',
          phoneNumber: '',
          employmentType: 'employer'
        };
      },
      closeSuccessPopup() {
        this.resetBookingData();
        this.isShownSuccess = false;
      },
      isViewableNow(isVisible, entry, section) {
        this.showAnimation[section] = isVisible;
      }
    }
  }
</script>

<style scoped>

  .booking-form {
    padding-bottom: 54px;

    @media (--viewport-tablet) {
      padding-bottom: 138px;
    }
  }

  .booking-form__wrapper {
    width: 100%;
    max-width: 608px;
  }

  .booking-form__title {
    text-align: center;
  }

  .booking-form__title--section {
    margin-bottom: 11px;
  }

  .booking-form__title--secondary {
    margin-bottom: 13px;
  }

  .booking-form__title--subtitle {
    margin-bottom: 11px;
  }

  .booking-form__section {
    padding-top: 11px;
    @media (--viewport-tablet) {
      padding-top: 45px;
    }
  }

  .booking-form__text {
    font-size: 0.75rem;
    line-height: 1.33;
    color: var(--text-color-tertiary);
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .booking-form__item--options {
    display: flex;
    align-items: center;
    margin-bottom: 23px;
  }

  .booking-form__item--button {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .booking-form__options {
    padding-left: 12px;
    display: inline-block;
  }

  .booking-form__button-wrapper {
    display: inline-block;
    margin-bottom: 21px;
  }

  .booking-form__notice {
    text-align: center;
    font-size: 0.875rem;
    color: var(--text-color-tertiary);
    line-height: 1.4;

    @media (--viewport-tablet) {
      max-width: 450px;
    }
  }

  .booking-form__link {
    color: var(--text-color-secondary);
    border-bottom: 1px solid var(--text-color-secondary);
  }
</style>
