var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "privacy-policy" },
    [
      _c("PrivacyPolicyBackButton"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "privacy-policy__section privacy-policy__section--header",
        },
        [
          _c(
            "div",
            { staticClass: "privacy-policy__wrapper wrapper" },
            [_c("PrivacyPolicyHeader")],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "privacy-policy__section privacy-policy__section--list",
        },
        [
          _c(
            "div",
            { staticClass: "privacy-policy__wrapper wrapper" },
            [_c("PrivacyPolicyText")],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }