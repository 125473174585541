<template>
  <div class="landing-pricing"
       v-observe-visibility="{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, 'header'), once: true}"
       :class="{ 'visible': showAnimation }"
  >
    <div class="landing-pricing__wrapper wrapper">
      <div
        class="landing-pricing__header"

      >
        <p class="landing-pricing__title landing-pricing__title--section section-title">PRICING</p>
        <h2 class="landing-pricing__title landing-pricing__title--secondary secondary-title">Sign up to GoPlanr for free today</h2>
        <p class="landing-pricing__title landing-pricing__title--subtitle text-secondary">GoPlanr only charges for what you use with no hidden fees or extras.</p>
      </div>

      <div class="landing-pricing__list">
        <div v-for="plan in getPlans()" class="landing-pricing__item">
          <PricingPlan :planDetails="plan" :withCounter="false"/>
        </div>
      </div>
      <div class="landing-pricing__box">
        <router-link :to="{ name: 'landingPricingPage' }" class="landing-pricing__link">
          Learn more about pricing plans
          <svg class="landing-pricing__ico">
            <use href="#arrow-right-circle"/>
          </svg>
        </router-link>
      </div>
    </div>
    <div class="landing-pricing__box landing-pricing__box--img only-tablet-desktop">
      <svg class="landing-pricing__img">
        <use href="#abstract-pricing"/>
      </svg>
      <svg class="landing-pricing__img landing-pricing__img--right only-tablet-desktop">
        <use href="#abstract-pricing"/>
      </svg>
    </div>
  </div>
</template>

<script>
  import PricingPlan from '../Pricing/PricingPlan';
  import PricingPlanMixin from '../../Mixins/PricingPlan';

  export default {
    components: { PricingPlan },
    mixins: [PricingPlanMixin],
    data() {
      return {
        showAnimation: false
      }
    },
    methods: {
      isViewableNow(isVisible) {
        this.showAnimation = isVisible;
      }
    }
  }
</script>

<style scoped>

  .landing-pricing {
    position: relative;
    padding-bottom: 42px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 288px;
      background-color: var(--text-color-primary);
    }
  }

  .landing-pricing__wrapper {
    position: relative;
    z-index: 2;
  }

  .landing-pricing__title {
    text-align: center;
  }

  .landing-pricing__title--section {
    margin-bottom: 11px;
  }

  .landing-pricing__title--subtitle {
    margin-bottom: 25px;
    @media (--viewport-tablet) {
      margin-bottom: 45px;
    }

  }

  .landing-pricing__list {
    margin-bottom: 42px;

    @media (--viewport-tablet) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 -16px 42px;
    }

    @media (--viewport-desktop) {
      display: flex;
      margin: 0 -16px 46px;
    }

  }

  .landing-pricing__item {
    width: 100%;
    box-sizing: border-box;

    @media (--viewport-tablet) {
      width: 50%;
      padding: 0 16px;
    }

    @media (--viewport-desktop) {
      width: 33.33%;
      padding: 0 16px;
    }
  }

  .landing-pricing__box {
    text-align: center;
  }

  .landing-pricing__link {
    display: inline-block;
    padding-right: 28px;
    position: relative;
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2;

    &:after {
      position: absolute;
      bottom: -2px;
      left: 0;
      content: '';
      height: 2px;
      width: calc(100% - 28px);
      background-color: var(--primary-color);
    }

    &:hover {
      &:after {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #00A8FF;
      }

      .landing-pricing__ico {
        fill: #29b6ff;
      }
    }
  }

  .landing-pricing__ico {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 20px;
    height: 20px;
    fill: var(--primary-color);
  }

  .landing-pricing__box--img {
    position: absolute;
    height: 114px;
    width: 100%;
    bottom: 0;
    z-index: 1;
    overflow: hidden;
  }

  .landing-pricing__img {
    width: 188px;
    height: 164px;
    position: absolute;
    bottom: -50px;
    left: -48px;
  }

  .landing-pricing__img--right {
    left: auto;
    right: -60px;
    transform: scale(-1, 1);
  }

</style>
