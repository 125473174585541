<template>
  <div class="pricing-plan" :class="{
    'pricing-plan--starter': isStarterPlan(planDetails.type),
    'pricing-plan--professional': isProfessionalPlan(planDetails.type)
  }">
    <div class="pricing-plan__header">
      <p class="pricing-plan__name">{{ planDetails.name }}</p>
      <svg class="pricing-plan__ico">
        <use :href="'#plan-' + planDetails.ico"/>
      </svg>
    </div>
    <div class="pricing-plan__data">
      <div class="pricing-plan__box pricing-plan__box--price">
        <div class="pricing-plan__price-wrapper">
          <span v-if="planDetails.price" class="pricing-plan__currency">£</span>
          <span class="pricing-plan__price">
            {{ priceText }}
          </span>
        </div>
        <span class="pricing-plan__text">{{ withQuantity ? planDetails.priceText : planDetails.pricePerUserText }}</span>
      </div>

      <div v-if="withQuantity" class="pricing-plan__box pricing-plan__box--counter">
        <div class="pricing-plan__counter">
          <PricingCounter
            :is-disabled="isStarterPlan(planDetails.type)"
            v-model="planDetails.employeesQuantity"
            @input="updatePriceText"
          />
        </div>
        <span class="pricing-plan__counter-text">Active users</span>
      </div>

      <div class="pricing-plan__list">
        <div v-for="feature in planDetails.features" class="pricing-plan__item">
          <svg class="pricing-plan__bullet">
            <use href="#checkmark"/>
          </svg>
          {{ feature }}
        </div>
      </div>

      <div class="pricing-plan__box pricing-plan__box--button">
        <div class="pricing-plan__button-wrapper">
          <ButtonRippleEffect
            :class="{
              'ripple-outer--light': isStarterPlan(planDetails.type),
              'ripple-outer--dark': isBasicPlan(planDetails.type),
            }"
          >
            <button
              type="button"
              class="pricing-plan__button button button--large"
              :class="{
                'button--stroke': isStarterPlan(planDetails.type),
                'button--secondary': isBasicPlan(planDetails.type),
                'button--primary': isProfessionalPlan(planDetails.type)
              }"
              @click="startPackagePlan(planDetails)"
            >
              {{ planDetails.buttonTitle }}
            </button>
          </ButtonRippleEffect>
        </div>

        <p v-html="withQuantity ? planDetails.hint : planDetails.description" class="pricing-plan__hint"/>
      </div>
    </div>
  </div>
</template>

<script>
  import PricingCounter from './PricingCounter.vue';
  import PricingPlanMixin from '../../Mixins/PricingPlan';
  import ButtonRippleEffect from "../Shared/ButtonRippleEffect";

  export default {
    components: {
      ButtonRippleEffect,
      PricingCounter
    },
    mixins: [PricingPlanMixin],
    props: ['planDetails', 'withQuantity'],
    data() {
      return {
        priceText: ''
      }
    },
    created() {
      this.updatePriceText();
    },
    methods: {
      updatePriceText() {
        if (this.planDetails.price) {
          const employeesMultiplier = this.withQuantity ? this.planDetails.employeesQuantity : 1;
          this.priceText = (this.planDetails.price * employeesMultiplier).toFixed(2);
        } else {
          this.priceText = 'Free';
        }
      },
      startPackagePlan(chosenPlan) {
        localStorage.setItem('chosenPackageOptions', JSON.stringify({
          numberOfEmployees: chosenPlan.employeesQuantity,
          packageType: chosenPlan.type
        }));
        window.location.href = '/sign/security-provider/registration/choose-plan';
      }
    }
  }
</script>

<style scoped>
  .pricing-plan {
    margin-bottom: 20px;
    border: 1px solid var(--divider-color);
    border-radius: 2px;
    background-color: var(--color-white);

    @media (--viewport-desktop) {
      margin-bottom: 0;
      min-height: 480px;
    }
  }

  .pricing-plan--counter {

    .pricing-plan__header {
      justify-content: center;
    }

    .pricing-plan__list {
      display: none;
    }

    .pricing-plan__box--price {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .pricing-plan--starter {
    .pricing-plan__ico {
      width: 16px;
      height: 16px;
      top: 24px;
      right: 32px;
    }

    .pricing-plan__name {
      color: var(--text-color-secondary);
    }

    .pricing-plan__text {
      text-transform: uppercase;
      color: var(--text-color-tertiary);

      @media (--viewport-desktop) {
        text-transform: none;
        color: var(--text-color-secondary);
      }
    }

    .pricing-plan__item {
      color: var(--text-color-secondary);
    }

    .pricing-plan__bullet {
      fill: var(--text-color-tertiary);
    }
  }

  .pricing-plan--professional {
    .pricing-plan__name {
      color: var(--primary-color);
    }

    .pricing-plan__bullet {
      fill: var(--primary-color);
    }
  }

  .pricing-plan__header {
    position: relative;
    min-height: 64px;
    padding: 20px 64px 20px 32px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--divider-color);
  }

  .pricing-plan__name {
    color: var(--text-color-primary);
    font-size: 1.25rem;
    line-height: 1.2;
  }

  .pricing-plan__ico {
    position: absolute;
    top: 16px;
    right: 24px;
    width: 32px;
    height: 32px;
  }

  .pricing-plan__data {
    padding: 22px 32px 20px;
    box-sizing: border-box;
  }

  .pricing-plan__box--price {
    margin-bottom: 22px;
    display: flex;
  }

  .pricing-plan__box--counter {
    margin-bottom: 45px;
    text-align: center;

  }

  .pricing-plan__currency {
    margin-right: 5px;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.3;
    font-feature-settings: 'salt' on;
    vertical-align: top;
  }

  .pricing-plan__price {
    margin-right: 8px;
    color: var(--text-color-primary);
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.3;

    @media (--viewport-tablet) {
      font-size: 3rem;
    }
  }

  .pricing-plan__text {
    align-self: center;
    max-width: 70px;
    color: var(--text-color-secondary);
    font-size: 0.875rem;
    line-height: 1.2;
  }

  .pricing-plan__counter-text {
    font-size: 0.875rem;
    line-height: 1.4;
    color: var(--text-color-tertiary);
  }

  .pricing-plan__counter {
    margin-bottom: 25px;
    display: flex;
    justify-content: center;

    @media (--viewport-desktop) {
      margin-bottom: 9px;
    }
  }

  .pricing-plan__list {
    margin-bottom: 40px;
  }

  .pricing-plan__item {
    position: relative;
    padding: 8px 0 8px 28px;
    font-size: 1rem;
    line-height: 1.5;
  }

  .pricing-plan__bullet {
    position: absolute;
    top: 10px;
    left: 0;
    width: 20px;
    height: 20px;
    fill: var(--primary-color-dark);
  }

  .pricing-plan__box--button {
    text-align: center
  }

  .pricing-plan__button-wrapper {
    margin-bottom: 20px;
  }

  .pricing-plan__button {
    width: 100%;
    min-width: 0;
    max-width: 300px;
  }

  .pricing-plan__hint {
    font-size: 0.875rem;
    line-height: 1.4;
    color: var(--text-color-tertiary);
  }
</style>
