<template>
  <div ref="header" class="header" :class="{ 'header--sticky': hasStickyHeader, 'header--remove-sticky': isRemovingStickyHeader }">
    <div class="header__wrapper wrapper">
      <div class="header__content">
        <div class="header__box header__box--logo">
          <a class="header__link" href="#" v-scroll-to="{element: '#app'}">
            <img class="header__img" src="/images/landing/gccs-logo-white.svg" alt="goplanr-logo-white">
            <img class="header__img header__img--dark" src="/images/landing/gccs-logo.svg" alt="goplanr-logo">
          </a>
        </div>

        <div class="header__box header__box--nav">
          <nav class="header__nav">
            <div class="header__item" v-for="menuItem in menuItems">
              <a
                class="header__link"
                :class="{ 'header__link--active': isActiveItem(menuItem.hash) }"
                :href="menuItem.hash"
                v-scroll-to="{element: menuItem.hash, offset: menuItem.offset}"
              >{{ menuItem.title }}</a>
            </div>
          </nav>
        </div>

        <div class="header__box header__box--actions">
          <div class="header__list">

            <div class="header__button-wrapper header__button-wrapper--login only-tablet-desktop">
              <LoginButton
                :class="{'login--primary':hasStickyHeader}"
              />
            </div>

            <div class="header__button-wrapper" v-if="hasStickyHeader">
              <ButtonRippleEffect>
                <button
                  type="button"
                  class="header__button button button--primary"
                  @click="gotoProviderRegistration()"
                >
                  Start Trial
                </button>
              </ButtonRippleEffect>
            </div>

            <div class="header__button-wrapper only-mobile-tablet">
              <button
                type="button"
                class="header__menu"
                @click="toggleSidebar"
              >
                <span class="header__bar"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <TheSidebar
        :class="{'sidebar--open': sidebarOpen}"
        v-on:close-sidebar="toggleSidebar"
      >
        <button
          class="header__close"
          type="button"
          @click="toggleSidebar"
        >
          <span class="header__close-wrapper">
            <svg class="header__close-ico">
              <use href="#close"/>
            </svg>
          </span>
        </button>
      </TheSidebar>
    </div>
  </div>
</template>

<script>
  import TheSidebar from './TheSidebar.vue'
  import ButtonRippleEffect from '../Shared/ButtonRippleEffect';
  import LoginButton from "../Shared/LoginButton";

  const HEADER_REMOVING_TIME = 450; // actually it should be 500 (animation is 0.5s) but if timeout is 500 - it blinks

  export default {
    components: {LoginButton, ButtonRippleEffect, TheSidebar },
    data() {
      return {
        hasStickyHeader: false,
        isRemovingStickyHeader: false,
        headerHeight: 0,
        sidebarOpen: false,
        currentSectionHash: '',
        menuItems: [{
          hash: '#about',
          title: 'About',
          offset: -64
        }, {
          hash: '#features',
          title: 'Features',
          offset: -100
        }, {
          hash: '#advantages',
          title: 'Advantages',
          offset: -64
        }, {
          hash: '#employees',
          title: 'Employees',
          offset: -100
        }, {
          hash: '#pricing',
          title: 'Pricing',
          offset: -100
        }, {
          hash: '#booking',
          title: 'Book a demo',
          offset: -100
        }]
      };
    },
    created() {
      window.addEventListener('scroll', this.handleScroll);
    },
    mounted() {
      this.headerHeight = this.$refs.header.scrollHeight;
      this.heroElemHeight = document.getElementById('hero').scrollHeight;
      this.sections = this.menuItems.map(function(menuItem) {
        return {
          element: document.querySelector(menuItem.hash),
          offset: menuItem.offset
        };
      });
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      handleScroll() {
        this.checkStickyHeader();
        this.setScrollSpy();
      },
      checkStickyHeader() {
        if (this.isRemovingStickyHeader) {
          if (window.pageYOffset <= this.headerHeight) { // if scrolled to initial header quickly
            this.removeStickyHeader();
          }
          return; // not change sticky header state
        }
        let extraHeight = this.hasStickyHeader ? 0 : 15;
        let newStickyHeader = window.pageYOffset >= this.heroElemHeight + extraHeight;
        this.updateStickyHeaderState(newStickyHeader);
      },
      updateStickyHeaderState(newHasStickyHeader) {
        if (this.hasStickyHeader && !newHasStickyHeader) {
          this.isRemovingStickyHeader = true;
          setTimeout(() => {
            this.removeStickyHeader();
          }, HEADER_REMOVING_TIME);
        } else if (!this.hasStickyHeader && newHasStickyHeader) {
          this.hasStickyHeader = newHasStickyHeader;
        }
      },
      removeStickyHeader() {
        this.hasStickyHeader = false;
        this.isRemovingStickyHeader = false;
      },
      setScrollSpy() {
        this.sections.some((section) => {
          const topRange = section.element.offsetTop + section.offset;
          const bottomRange = section.element.offsetTop + section.element.offsetHeight + section.offset;
          if (window.pageYOffset >= topRange && window.pageYOffset <= bottomRange) {
            this.currentSectionHash = `#${section.element.id}`;
            return true;
          } else {
            this.currentSectionHash = '';
            return false;
          }
        });
      },
      isActiveItem(hash) {
        return this.currentSectionHash === hash;
      },
      toggleSidebar() {
        this.sidebarOpen = !this.sidebarOpen;
        document.body.classList.toggle('popup-open', this.sidebarOpen);
      },
      gotoProviderRegistration() {
        window.location.href = '/sign/security-provider/registration/choose-plan';
      }
    }
  }
</script>

<style scoped>
  .header {
    background-color: var(--text-color-primary);
    height: var(--mobile-header-height);
    border-bottom: 1px solid rgba(40, 68, 148, 0.5);
    box-sizing: border-box;
    overflow: hidden;

    @media (--viewport-desktop) {
      height: var(--desktop-header-height);
    }
  }

  .header--sticky {
    position: sticky;
    top: 0;
    z-index: 99;
    height: var(--desktop-sticky-header-height);
    background-color: var(--color-white);
    border-bottom-color: var(--divider-color);
    animation: slideDown .5s ease-out;

    .header__link {
      color: var(--text-color-secondary);
    }

    .header__link--active {
      color: var(--primary-color);
    }

    .header__bar {
      background-color: var(--primary-color-dark);
    }

    .header__img {
      display: none;
    }

    .header__img--dark {
      display: block;
    }
  }

  .header--remove-sticky {
    animation: slideUp .5s ease-out;
  }

  .header__wrapper {
    height: 100%;
    box-sizing: border-box;
  }

  .header__img {
    width: 115px;
    height: 32px;
  }

  .header__img--dark {
    display: none;
  }

  .header__content {
    margin: 0 -20px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .header__box {
    padding: 0 20px;
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }

  .header__box--logo {
    flex-grow: 1;

    @media (--viewport-desktop) {
      flex-grow: 0;
    }
  }


  .header__box--nav {
    display: none;
    @media (--viewport-desktop) {
      display: flex;
      flex-grow: 1;
    }
  }

  .header__item {
    display: inline-block;
    padding: 0 16px;
  }


  .header__link {
    font-size: 0.875rem;
    line-height: 1.14;
    color: var(--color-white);
  }

  .header__list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 -12px;

    @media (--viewport-desktop){
      margin: 0 -8px;
    }
  }

  .header__button-wrapper {
    padding: 0 5px;

    @media (--viewport-mobile){
      padding: 0 12px;
    }

    @media (--viewport-desktop){
      padding: 0 8px;
    }
  }

  .header__button {
    padding: 0 8px;

    @media (--viewport-mobile) {
      padding: 0 12px;
    }

    @media (--viewport-desktop){
      min-width: 148px;
    }
  }

  .header__menu {
    display: block;
    position: relative;
    width: 32px;
    height: 32px;
  }

  .header__bar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 24px;
    height: 3px;
    background-color: var(--color-white);
    border-radius: 5px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: inherit;
      width: inherit;
      height: inherit;
      border-radius: 5px;
    }

    &:before {
      top: -7px;
    }

    &:after {
      bottom: -7px;
    }

  }

  .header__close {}

  .header__close-wrapper {
    display: block;
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid var(--text-color-tertiary);
  }

  .header__close-ico {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 8px;
    height: 8px;
    fill: var(--text-color-tertiary);
  }

  @keyframes slideDown {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideUp {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-100%);
    }
  }



</style>
