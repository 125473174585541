import axios from '@/core/Services/Axios';

let generateTimestamp = () => {
  return (new Date()).getTime();
};

export function apiSignCheckUser(email, password) {
  return axios.put('/session/check?timestamp=' + generateTimestamp(), {email: email, password: password});
}

export function apiSignCreateSession(email, password) {
  return axios.post('/session?timestamp=' + generateTimestamp(), {email: email, password: password});
}

export function setSessionId(sessionId) {
  return localStorage.setItem('sessionId', sessionId);
}
