<template>
  <div class="terms-and-conditions simple-text">
    <a href="https://www.iubenda.com/terms-and-conditions/73958017" class="iubenda-nostyle no-brand iubenda-embed iub-no-markup iub-body-embed" title="Terms and Conditions">Terms and Conditions</a>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        termsAndConditions: ''
      }
    },
    created() {
      const w = window;
      const d = document;
      const loader = function () {
        const s = d.createElement("script"), tag = d.getElementsByTagName("script")[0];
        s.src="https://cdn.iubenda.com/iubenda.js";
        tag.parentNode.insertBefore(s, tag);
      };
      if (w.addEventListener) {
        w.addEventListener("load", loader, false);
      }else if (w.attachEvent) {
        w.attachEvent("onload", loader);
      }
      else {
        w.onload = loader;
      }
    }
  }
</script>

<style>
  /* hide duplicated title */
  .iub_header h1 {
    display: none;
  }
</style>
