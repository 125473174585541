var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "landing-hero", attrs: { id: "hero" } }, [
    _c("div", { staticClass: "landing-hero__wrapper wrapper" }, [
      _c("div", { staticClass: "landing-hero__content" }, [
        _c(
          "div",
          { staticClass: "landing-hero__box landing-hero__box--content" },
          [
            _c("h1", { staticClass: "landing-hero__title" }, [
              _vm._v("Manage your workforce"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "landing-hero__subtitle" }, [
              _vm._v("easily and efficiently"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "landing-hero__text" }, [
              _vm._v(
                "A dedicated solution for workforce agencies who need to deploy employees to multiple client sites."
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "landing-hero__button-wrapper" },
              [
                _c("ButtonRippleEffect", [
                  _c(
                    "button",
                    {
                      staticClass:
                        "landing-hero__button button button--primary button--large",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.gotoProviderRegistration()
                        },
                      },
                    },
                    [_vm._v("Start Free Month Trial")]
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("p", { staticClass: "landing-hero__notice" }, [
              _vm._v("No credit card required"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "landing-hero__box landing-hero__box--img" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "landing-hero__media-wrapper landing-hero__media-wrapper--video",
            },
            [
              _c("img", {
                staticClass: "landing-hero__img",
                attrs: { src: "/images/landing/desktop-header.png" },
              }),
              _vm._v(" "),
              _c(
                "video",
                {
                  staticClass: "landing-hero__media landing-hero__media--video",
                  attrs: { autoplay: "", loop: "", muted: "" },
                  domProps: { muted: true },
                },
                [
                  _c("source", {
                    attrs: {
                      src: "/videos/landing/main.mp4",
                      type: "video/mp4",
                    },
                  }),
                  _vm._v(
                    "\n            Your browser does not support the video tag.\n          "
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass:
                "landing-hero__ico landing-hero__ico--lines only-desktop",
            },
            [_c("use", { attrs: { href: "#lines" } })]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass:
                "landing-hero__ico landing-hero__ico--star-left only-desktop",
            },
            [_c("use", { attrs: { href: "#star" } })]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass:
                "landing-hero__ico landing-hero__ico--star-right only-desktop",
            },
            [_c("use", { attrs: { href: "#star" } })]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "landing-hero__media-wrapper" }, [
      _c("img", {
        staticClass: "landing-hero__media",
        attrs: {
          src: "/images/landing/hero-phone@1x.png",
          srcset:
            "/images/landing/hero-phone@1x.png" +
            " 1x, " +
            "/images/landing/hero-phone@2x.png" +
            " 2x",
          alt: "GoPlanr mobile platform example",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "landing-hero__media-wrapper" }, [
      _c("img", {
        staticClass: "landing-hero__media",
        attrs: {
          src: "/images/landing/hero-desktop@1x.png",
          srcset:
            "/images/landing/hero-desktop@1x.png" +
            " 1x, " +
            "/images/landing/hero-desktop@2x.png" +
            " 2x",
          alt: "GoPlanr web platform example",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }