<template>
  <div
    class="pricing-counter"
    :class="{ 'pricing-counter--disabled': isDisabled }"
  >
    <button
      class="pricing-counter__button"
      type="button"
      :class="{ 'pricing-counter__button--disabled': isMinimumValue }"
      :disabled="isMinimumValue"
      @click="decreaseValue"
    />
    <input
      class="pricing-counter__input"
      type="number"
      v-mask="'###'"
      :value="value"
      @input="$emit('input', $event.target.value)"
    >
    <button
      type="button"
      class="pricing-counter__button pricing-counter__button--plus"
      @click="increaseValue"
    />

  </div>
</template>

<script>
  import { mask } from 'vue-the-mask'

  export default {
    props: ['value', 'isDisabled'],
    directives: { mask },
    mounted() {
      // fix for somehow cached input value
      setTimeout(() => {
        this.$forceUpdate();
      });
    },
    computed: {
      isMinimumValue() {
        return this.value <= 1;
      }
    },
    methods: {
      increaseValue() {
        this.$emit('input', +this.value + 1);
      },
      decreaseValue() {
        if (this.isMinimumValue) { return; }
        this.$emit('input', +this.value - 1);
      }
    }
  }
</script>

<style scoped>
  .pricing-counter {
    display: flex;
    align-items: center;
    padding: 0 9px;
    max-width: 144px;
    height: 40px;
    border: 1px solid #DFE3E8;
    border-radius: 2px;
    box-sizing: border-box;
  }

  .pricing-counter--disabled {
    pointer-events: none;
    border: 1px dashed #DFE3E8;

    .pricing-counter__button {
      pointer-events: none;
      &:before {
        opacity: 0.4;
      }
      &:after {
        opacity: 0.4;
      }
    }
  }

  .pricing-counter__button {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 1px;

    &:hover {
      @media (--viewport-desktop) {
        background: rgba(0, 168, 152, 0.08);

        &:before,
        &:after {
          background-color: var(--primary-color);
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: 13px;
      height: 2px;
      background-color: var(--text-color-tertiary);
      border-radius: 2px;
    }
  }

  .pricing-counter__button--plus {
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: 2px;
      height: 13px;
      background-color: var(--text-color-tertiary);
      border-radius: 2px;
    }
  }

  .pricing-counter__button--disabled {
    pointer-events: none;
    &:before {
      opacity: 0.4;
    }
    &:after {
      opacity: 0.4;
    }
  }

  .pricing-counter__input {
    padding: 5px;
    text-align: center;
    width: 80px;
    height: 100%;
    box-sizing: border-box;
  }

</style>
