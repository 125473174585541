<template>
  <div
    class="feature-item"
    :class="{ 'feature-item--left': featureDetails.isImgLeft }"
  >
    <div class="feature-item__content">
      <div class="feature-item__header">
        <div class="feature-item__ico-wrapper">
          <svg class="feature-item__ico">
            <use :href="'#feature-' + featureDetails.ico "/>
          </svg>
        </div>
        <span class="feature-item__name">{{ featureDetails.sectionName }}</span>
      </div>
      <p class="feature-item__title">{{ featureDetails.title }}</p>
      <ul class="feature-item__list">
        <li class="feature-item__text" v-for="item in featureDetails.featureList">
          <svg class="feature-item__checkmark">
            <use href="#checkmark"/>
          </svg>
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="feature-item__img-wrapper">
      <img
        class="feature-item__img"
        :src="'/images/landing/feature-' + featureDetails.img + '.png'"
        :alt="featureDetails.sectionName"
      >
    </div>
  </div>
</template>

<script>
  export default {
    props: ['featureDetails']
  }
</script>

<style scoped>

  .feature-item {
    @media (--viewport-desktop) {
      display: flex;
      width: 100%;
    }
  }

  .feature-item__content {

    @media (--viewport-tablet) {
      margin: 0 auto;
      max-width: 512px;
    }

    @media (--viewport-desktop) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      margin: 0;
      max-width: 432px;
      padding-right: 96px;
    }
  }

  .feature-item--left {
    .feature-item__content {

      @media (--viewport-desktop) {
        padding-left: 96px;
        padding-right: 0;
      }

    }

    .feature-item__img-wrapper {

      @media (--viewport-desktop) {
        text-align: left;
        order: -1;
      }

      .feature-item__img {
        @media (--viewport-tablet) {
          max-width: 544px;
        }
      }
    }
  }

  .feature-item__header {
    position: relative;
    margin-bottom: 10px;
  }

  .feature-item__ico-wrapper {
    margin-right: 16px;
    position: absolute;
    top: 0;
    left: 8px;
    width: 16px;
    height: 16px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: 32px;
      height: 32px;
      border-radius: 4px;
      background-color: #E6F6FF;
      z-index: -1;
    }
  }

  .feature-item__ico {
    width: 100%;
    height: 100%;
    fill: var(--primary-color);
  }

  .feature-item__name {
    padding-left: 48px;
    color: var(--primary-color);
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.4;
  }

  .feature-item__title {
    margin-bottom: 10px;
    padding-left: 48px;
    font-size: 1.75rem;
    line-height: 1.28;
  }

  .feature-item__img-wrapper {
    width: 100%;
    height: auto;

    @media (--viewport-desktop) {
      text-align: right;
    }
  }

  .feature-item__img {
    width: 100%;
    height: auto;

    @media (--viewport-tablet) {
      max-width: 592px;
    }

  }

  .feature-item__list {
    margin-bottom: 24px;

    @media (--viewport-tablet) {
      margin-bottom: 48px;
    }

    @media (--viewport-desktop) {
      margin-bottom: 0;
    }
  }

  .feature-item__text {
    position: relative;
    padding: 8px 0 8px 48px;
    line-height: 1.5;
  }

  .feature-item__checkmark {
    position: absolute;
    left: 8px;
    top: 8px;
    width: 24px;
    height: 24px;
    fill: var(--primary-color);
  }

</style>
