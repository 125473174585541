<template>
  <div class="privacy-policy">
    <PrivacyPolicyBackButton/>
    <div class="privacy-policy__section privacy-policy__section--header">
      <div class="privacy-policy__wrapper wrapper">
        <PrivacyPolicyHeader/>
      </div>
    </div>
    <div class="privacy-policy__section privacy-policy__section--list">
      <div class="privacy-policy__wrapper wrapper">
        <PrivacyPolicyText/>
      </div>
    </div>
  </div>
</template>

<script>
  import PrivacyPolicyBackButton from '../Components/PrivacyPolicy/PrivacyPolicyBackButton.vue';
  import PrivacyPolicyHeader from '../Components/PrivacyPolicy/PrivacyPolicyHeader.vue';
  import PrivacyPolicyText from '../Components/PrivacyPolicy/PrivacyPolicyText.vue';

  export default {
    components: {
      PrivacyPolicyBackButton,
      PrivacyPolicyHeader,
      PrivacyPolicyText
    }
  }
</script>

<style scoped>
  .privacy-policy__wrapper {
    max-width: 1160px;
  }
  .privacy-policy__section--header {
    margin-bottom: 40px;

    @media (--viewport-desktop) {
      margin-bottom: 62px;
    }
  }

  .privacy-policy__section--table {
    margin-bottom: 40px;

    @media (--viewport-desktop) {
      margin-bottom: 80px;
    }
  }

  .privacy-policy__section--list {
    padding-bottom: 42px;
    @media (--viewport-tablet) {
      position: relative;
      background-color: var(--light-background);

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 64px;
        background-color: var(--color-white);
        z-index: 0;
      }
    }

    @media (--viewport-desktop) {
      background-color: transparent;
      &:after {
        content: none;
      }
    }
  }

</style>
