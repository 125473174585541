import { apiSignCheckUser, apiSignCreateSession, setSessionId } from '../Services/Axios';
const SET_PAGE_TITLE = 'setPageTitle';

const state = {
  pageTitle: null,
  signInErrors: []
};

const mutations = {
  [SET_PAGE_TITLE] (state, pageTitle) {
    state.pageTitle = pageTitle;
  }
};

const actions = {
  async signCheckUser ({commit}, {email, password}) {
    await apiSignCheckUser(email, password);
  },
  async signIn ({commit}, {email, password}) {
    const createSession = await apiSignCreateSession(email, password);
    setSessionId(createSession.data.encrypted_id);
  }
};

const getters = {
  pageTitle: state => state.pageTitle
};

const signStore = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default signStore;
