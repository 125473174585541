var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return false
    ? _c("div", { staticClass: "social-links" }, [
        _c(
          "a",
          {
            staticClass: "social-links__item",
            attrs: { href: "#", target: "_blank" },
          },
          [
            _c("svg", { staticClass: "social-links__ico" }, [
              _c("use", { attrs: { href: "#fb" } }),
            ]),
            _vm._v(" "),
            _c("svg", { staticClass: "social-links__lines" }, [
              _c("use", { attrs: { href: "#lines" } }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "social-links__item",
            attrs: { href: "#", target: "_blank" },
          },
          [
            _c("svg", { staticClass: "social-links__ico" }, [
              _c("use", { attrs: { href: "#linkedin" } }),
            ]),
            _vm._v(" "),
            _c("svg", { staticClass: "social-links__lines" }, [
              _c("use", { attrs: { href: "#lines" } }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "social-links__item",
            attrs: { href: "#", target: "_blank" },
          },
          [
            _c("svg", { staticClass: "social-links__ico" }, [
              _c("use", { attrs: { href: "#twitter" } }),
            ]),
            _vm._v(" "),
            _c("svg", { staticClass: "social-links__lines" }, [
              _c("use", { attrs: { href: "#lines" } }),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }