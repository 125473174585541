<template>
  <div class="landing-app-description">
    <div class="landing-app-description__wrapper wrapper">
      <div class="landing-app-description__content">
        <p class="landing-app-description__title">Download the GoPlanr App for Employees</p>
        <p class="landing-app-description__text text-secondary">If your employer has asked you to download the GoPlanr App to manage your schedule, please click to download the IOS or Android app from the app store</p>
        <div class="landing-app-description__list">
          <AppPromoLinks class="app-promo-links--description"/>
        </div>
      </div>
      <img class="landing-app-description__img" src="/images/landing/goplanr-app.png">
    </div>
  </div>
</template>

<script>
  import AppPromoLinks from "../AppPromo/AppPromoLinks";
  export default {
    components: { AppPromoLinks }
  }
</script>

<style scoped>
  .landing-app-description {
    position: relative;
  }

  .landing-app-description__wrapper {
    padding-bottom: 70px;
    position: relative;
  }

  .landing-app-description__content {
    position: relative;
    padding: 22px 20px 148px;
    border: 1px solid var(--divider-color);
    border-radius: 2px;
    overflow: hidden;
    box-sizing: border-box;

    @media (--viewport-tablet) {
      padding: 35px 40px 198px;
    }

    @media (--viewport-desktop) {
      padding-bottom: 40px;
    }


    &:after {
      content: '';
      position: absolute;
      bottom: -191px;
      right: 50%;
      transform: translateX(50%);
      width: 347px;
      height: 347px;
      background: #F6FAFC;
      border-radius: 50%;

      @media (--viewport-tablet) {
        bottom: -250px;
      }

      @media (--viewport-desktop) {
        transform: translateX(0);
        right: -59px;
        bottom: -129px;
      }
    }
  }

  .landing-app-description__title {
    margin-bottom: 18px;
    font-size: 1.25rem;
    color: var(--text-color-primary);
    line-height: 1.4;
    text-align: center;

    @media (--viewport-tablet) {
      font-size: 1.75rem;
    }

    @media (--viewport-desktop) {
      max-width: 620px;
      text-align: left;
    }
  }

  .landing-app-description__text {
    margin-bottom: 33px;
    text-align: center;

    @media (--viewport-desktop) {
      max-width: 620px;
      text-align: left;
    }
  }

  .landing-app-description__list {
    position: relative;
    z-index: 1;

    @media (--viewport-tablet) {
      display: flex;
      justify-content: center;
    }

    @media (--viewport-desktop) {
      justify-content: flex-start;
    }
  }

  .landing-app-description__img {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    width: 96px;
    height: 151px;

    @media (--viewport-tablet) {
      width: 136px;
      height: 225px;
    }

    @media (--viewport-desktop) {
      right: 82px;
      bottom: auto;
      top: 0;
      transform: translateX(0);
      width: 155px;
      height: 257px;
    }

  }
</style>
