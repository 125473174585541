<template>
  <div class="landing-employees">
    <div class="landing-employees__box landing-employees__box--img only-desktop">

    <div class="landing-employees__img-wrapper ">
      <video class="landing-employees__img" width="288" height="506" autoplay loop muted>
        <source src="/videos/landing/app.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>

    </div>
    <div class="landing-employees__box landing-employees__box--content">
      <div
        class="landing-employees__header"
        v-observe-visibility="{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, 'header'), once: true}"
        :class="{'visible': showAnimation.header}"
      >
        <p class="landing-employees__title section-title">Employees</p>
        <h2 class="landing-employees__title landing-employees__title--secondary secondary-title">Bring shift allocation and staff interaction into the mobile age</h2>
        <p class="landing-employees__title landing-employees__title--subtitle text-secondary">Allow your employees to interact with you and their schedules using their smart phones.</p>
      </div>

      <div class="landing-employees__img-wrapper only-mobile-tablet">
        <video class="landing-employees__img" width="288" height="506" autoplay loop muted playsinline>
          <source src="/videos/landing/app.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>

      <ul
        class="landing-employees__list"
        v-observe-visibility="{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, 'list'), once: true}"
        :class="{'visible': showAnimation.list}"
      >
        <p class="landing-employees__text">Using the Staff portal and mobile apps your staff can:</p>
        <li class="landing-employees__item">
          <svg class="landing-employees__ico"><use href="#checkmark"></use></svg>
          View their schedule in real time
        </li>
        <li class="landing-employees__item">
          <svg class="landing-employees__ico"><use href="#checkmark"></use></svg>
          Respond to shift invites
        </li>
        <li class="landing-employees__item">
          <svg class="landing-employees__ico"><use href="#checkmark"></use></svg>
          Request time off and set availability
        </li>
        <li class="landing-employees__item">
          <svg class="landing-employees__ico"><use href="#checkmark"></use></svg>
          View timesheets for payroll
        </li>
        <li class="landing-employees__item">
          <svg class="landing-employees__ico"><use href="#checkmark"></use></svg>
          Contractors / Self Employed can generate their invoices through their portal saving them and you time
        </li>
      </ul>
      <div
        class="landing-employees__testimonial"
        v-observe-visibility="{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, 'testimonial'), once: true}"
        :class="{'visible': showAnimation.testimonial}"

      >
        <LandingTestimonial
          text="“GoPlanr has allowed us to manage our staff efficiently across all our sites across the UK. Great system and intuitive to use.”"
          name="Daniel Pitt"
          title="National Account Manager at MAST"
          img="mast-logo"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import LandingTestimonial from './LandingTestimonial';

  export default {
    components: { LandingTestimonial },
    data() {
      return {
        showAnimation: {
          header: false,
          list: false,
          testimonial: false
        }
      }
    },
    methods: {
      isViewableNow(isVisible, entry, section) {
        this.showAnimation[section] = isVisible;
      }
    }
  }
</script>

<style scoped>

  .landing-employees {
    @media (--viewport-desktop) {
      white-space: nowrap;
    }
  }

  .landing-employees__box {

    @media (--viewport-desktop) {
      white-space: normal;
      vertical-align: top;
      display: inline-block;
      width: 57%;
      box-sizing: border-box;
    }

  }

  .landing-employees__box--img {
    @media (--viewport-desktop) {
      position: sticky;
      top: calc(var(--desktop-sticky-header-height) + 40px);
    }
  }

  .landing-employees__box--content {
    @media (--viewport-desktop) {
      width: 43%;
    }
  }

  .landing-employees__img-wrapper {
    margin-bottom: 40px;
    position: relative;
    width: 100%;
    text-align: center;
    box-sizing: border-box;

    @media (--viewport-tablet) {
      margin-bottom: 76px;
    }

    @media (--viewport-desktop) {
      margin-bottom: 0;
      text-align: left;
      padding-left: 64px;
    }

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: 512px;
      height: 80px;
      background-color: var(--primary-color);

      @media (--viewport-tablet) {
        content: '';
      }

      @media (--viewport-desktop) {
        width: 416px;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }

  .landing-employees__img {
    position: relative;
    width: 100%;
    max-width: 288px;
    height: auto;
    z-index: 1;
    box-shadow: 0 0 72px rgba(19, 33, 74, 0.02);
  }

  .landing-employees__title {

    text-align: center;

    @media (--viewport-desktop) {
      text-align: left;
    }
  }

  .landing-employees__title--secondary {
    margin-bottom: 19px;
  }

  .landing-employees__title--subtitle {
    margin-bottom: 40px;

    @media (--viewport-tablet) {
      margin-bottom: 85px;
    }
  }

  .landing-employees__text {
    margin-bottom: 8px;
    font-size: 1.25rem;
    line-height: 1.4;

    @media {
      max-width: 360px;
    }
  }

  .landing-employees__list {
    margin-bottom: 23px;
    @media {
      margin-bottom: 44px;
    }
  }

  .landing-employees__item {
    position: relative;
    padding: 8px 0 8px 28px;
    color: var(--text-color-secondary);
    line-height: 1.5;
  }

  .landing-employees__ico {
    position: absolute;
    top: 10px;
    left: 0;
    width: 20px;
    height: 20px;
    fill: var(--primary-color);
  }
</style>
