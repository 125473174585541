<template>
  <div class="pricing-testimonials">
    <div class="pricing-testimonials__list">
      <div v-for="item in testimonials" class="pricing-testimonials__item">
        <LandingTestimonial
          :text="item.text"
          :name="item.name"
          :title="item.title"
          :img="item.img"
          class="landing-testimonial--flex"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import LandingTestimonial from '../Home/LandingTestimonial';

  export default {
    components: { LandingTestimonial },
    data() {
      return {
        testimonials: [
        {
          text: '“Our staff find the app easy to use and great know where they are working and what their duties are.”',
          name: 'Paul Steele',
          title: 'Operations Manager at Armatus Risks Security Services',
          img: 'armadus-logo'
        },
        {
          text: '“GoPlanr helped us to streamline our operations and back office activities giving us more time to concentrate on our clients and the service we provide.”',
          name: 'Denes Doman',
          title: 'Head of Operations at GC Covert Security',
          img: 'gccs-logo'
        },
        {
          text: '“GoPlanr has allowed us to manage our staff efficiently across all our sites across the UK. Great system and intuitive to use”',
          name: 'Daniel Pitt',
          title: 'National Account Manager at MAST',
          img: 'mast-logo',
        }]
      }
    }
  }
</script>

<style scoped>
  .pricing-testimonials {}

  .pricing-testimonials__list {
    @media (--viewport-desktop) {
      display: flex;
      align-items: flex-start;
      margin: 0 -16px;
    }
  }

  .pricing-testimonials__item {
    margin-bottom: 20px;

    @media (--viewport-desktop) {
      margin-bottom: 0;
      padding: 0 16px;
      display: inline-block;
      width: 33.33%;
      box-sizing: border-box;

    }
  }

</style>
