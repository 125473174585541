<template>
  <div class="privacy-policy-list">
    <PrivacyPolicy/>
  </div>
</template>

<script>
  import PrivacyPolicy from '../PrivacyPolicy.vue';

  export default {
    components: { PrivacyPolicy }
  }
</script>

<style scoped>
  .privacy-policy-list {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--viewport-desktop) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      margin: 0 -16px 46px;
    }
  }

  .privacy-policy-list__item {
    width: 100%;
    max-width: 368px;
    box-sizing: border-box;

    @media (--viewport-desktop) {
      width: 33.33%;
      max-width: none;
      padding: 0 16px;
    }
  }

</style>
