import Vue from 'vue';
import Router from 'vue-router';
import AppPage from '../Pages/AppPage';

import { LANDING_ROUTER } from '@/modules/Landing/Routes/LandingRouter';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [{
    path: '/',
    component: AppPage,
    children: [
      LANDING_ROUTER
    ]
  }],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
