<template>
  <ValidationProvider :name="name" :rules="validationRules" v-slot="{ errors }" slim>
    <div class="form-input" :class="{
      'form-input--active': isFocused || value || errors.length > 0,
      'form-input--error': errors.length > 0
    }">
      <input
        v-if="!isTextarea"
        :id="id"
        class="form-input__field"
        :type="inputType"
        :placeholder="placeholder"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @focus="onInputFocus()"
        @blur="onInputBlur()"
      >
      <textarea
        v-if="isTextarea"
        :id="id"
        class="form-input__field form-input__field--textarea"
        rows="2"
        @input="updateTextarea"
        @focus="onInputFocus()"
        @blur="onInputBlur()"
        :type="inputType"
        :placeholder="placeholder"
        :value="value"
        ref="textareaElement"
      />
      <div class="form-input__data">
        <span class="form-input__highlight"/>
        <span class="form-input__bar"/>
        <label :for=id class="form-input__label">{{ label }}</label>
        <span v-if="errors.length > 0" class="form-input__error">{{ errors[0] }}</span>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
  export default {
    props: ['name', 'value', 'id', 'label', 'placeholder', 'inputType', 'isTextarea', 'validationRules'],
    data() {
      return {
        isFocused: false
      }
    },
    methods: {
      updateTextarea($event) {
        this.$refs.textareaElement.style.height = '';
        this.$refs.textareaElement.style.height = $event.target.scrollHeight + 1 + 'px';
        this.$emit('input', $event.target.value)
      },
      onInputFocus() {
        this.isFocused = true;
      },
      onInputBlur() {
        this.isFocused = false;
      }
    }
  }
</script>

<style scoped>
  .form-input {
    margin-top: 15px;
    padding-bottom: 23px;
    position: relative;
  }

  .form-input--active {
    outline: none;

    .form-input__field {
      &::placeholder {
        color: var(--text-color-tertiary);
      }
    }

    .form-input__label {
      top: -14px;
      font-size: 0.75rem;
      text-transform: uppercase;
      color: var(--primary-color);
    }

    .form-input__bar {
      &:after {
        width: 100%;
      }
    }
  }

  .form-input--error {
    .form-input__error {
      display: block;
    }

    .form-input__field {
      &:focus ~ .form-input__data {
        .form-input__label {
          color: var(--error-color);
        }
      }
    }

    .form-input__label {
      color: var(--error-color);
    }

    .form-input__bar {
      &:after {
        background-color: var(--error-color);
      }
    }
  }

  .form-input__field {
    background: none;
    color: var(--color-black);
    font-size: 1.25rem;
    line-height: 1.2;
    padding: 5px 0;
    display: block;
    width: 100%;
    height: 34px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #DFE3E8;
    box-sizing: border-box;

    &::placeholder {
      color: transparent;
    }

    &:focus ~ .form-input__data {
      .form-input__label {
        top: -14px;
        font-size: 0.75rem;
        text-transform: uppercase;
        color: var(--primary-color);
      }

      .form-input__bar {
        &:after {
          width: 100%;
        }
      }
    }

    &:not(:placeholder-shown) ~ .form-input__data {
      .form-input__label {
        top: -14px;
        font-size: 0.75rem;
        text-transform: uppercase;
        color: var(--primary-color);
      }


      .form-input__bar {
        &:after {
          width: 100%;
        }
      }
    }

    &:not(:-ms-input-placeholder) ~ .form-input__data {
      .form-input__label {
        top: -14px;
        font-size: 0.75rem;
        text-transform: uppercase;
        color: var(--primary-color);
      }


      .form-input__bar {
        &:after {
          width: 100%;
        }
      }
    }
  }

  .form-input__field--textarea {
    resize: none;
    padding-right: 5px;
    height: 35px;
  }


  .form-input__label {
    color: var(--text-color-primary);
    font-size: 1.25rem;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 1px;
    transition: 300ms ease all;
  }

  .form-input__bar {
    position: relative;
    display: block;
    width: 100%;

    &:after {
      content: '';
      height: 1px;
      width: 0;
      bottom: 0;
      position: absolute;
      background: var(--primary-color);
      transition: 300ms ease all;
      left: 0;
      z-index: 1;
    }
  }

  .form-input__error {
    display: none;
    position: absolute;
    left: 0;
    bottom: 6px;
    color: var(--error-color);
    font-size: 0.75rem;
    line-height: 1.33;
  }
</style>
