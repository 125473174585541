<template>
  <div class="app-promo">
    <div class="app-promo__wrapper wrapper">
      <div class="app-promo__content">
        <div class="app-promo__box app-promo__box--content">
          <p class="app-promo__title">
            Download the GoPlanr App for Employees
          </p>
          <div class="app-promo__list">
            <AppPromoLinks/>
          </div>
        </div>

        <div class="app-promo__box app-promo__box--img">
          <div class="app-promo__media">
            <div class="app-promo__img-wrapper">
              <svg class="app-promo__star">
                <use href="#star"></use>
              </svg>
              <img class="app-promo__img"
                   src="images/landing/app-screen@1x.png"
                   srcset="/images/landing/app-screen@1x.png 1x,
                   /images/landing/app-screen@2x.png 2x"
                   alt="Example of GoPlanr app">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AppPromoLinks from "./AppPromoLinks";

  export default {
    components: {AppPromoLinks}
  }
</script>

<style scoped>
  .app-promo {
    background-color: var(--primary-color-dark);


    @media (--viewport-desktop) {
      height: 120px;
    }
  }

  .app-promo__wrapper {
    height: 100%;
  }

  .app-promo__content {
    height: 100%;

    @media (--viewport-tablet) {
      display: flex;
      align-items: flex-end;
    }

    @media (--viewport-desktop) {
      display: flex;
      align-items: center;
    }

  }

  .app-promo__box--content {
    padding-top: 32px;

    @media (--viewport-tablet) {
      flex-grow: 1;
      padding-top: 22px;
    }

    @media (--viewport-desktop) {
      display: flex;
      padding-top: 0;
      align-items: center;
    }
  }

  .app-promo__box--img {
    width: 100%;
    height: 168px;
    position: relative;

    @media (--viewport-tablet) {
      width: 301px;
      height: 100%;
    }

    @media (--viewport-desktop) {
      width: 288px;
      height: 100%;
    }

  }

  .app-promo__list {
    margin-bottom: 32px;

    @media (--viewport-tablet) {
      margin-bottom: 0;
      padding-bottom: 20px;
    }

    @media (--viewport-desktop) {
      padding-bottom: 0;
      order: -1;
    }
  }

  .app-promo__media {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    right: 0;
    box-sizing: border-box;
    overflow: hidden;

    @media (--viewport-tablet) {
      right: -20px;
      height: 168px;
      width: 284px;
    }

    @media (--viewport-desktop-big) {
      padding-right: 55px;
      justify-content: flex-end;
      right: -55px;
      height: 168px;
      width: 284px;
    }

  }

  .app-promo__img-wrapper {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      right: 50%;
      transform: translateX(50%);
      top: 98px;
      width: 347px;
      height: 347px;
      background-color: var(--text-color-primary);
      border-radius: 50%;

      @media (--viewport-desktop-big) {
      }

    }
  }

  .app-promo__title {
    margin-bottom: 32px;
    color: var(--color-white);
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.2;
    font-weight: 600;

    @media (--viewport-tablet) {
      margin-bottom: 14px;
      text-align: left;
      font-size: 1rem;
      line-height: 1.5;
    }

    @media (--viewport-desktop) {
      flex-grow: 1;
      margin-bottom: 0;
      text-align: center;
      font-weight: normal;
      font-size: 1.25rem;
    }
  }

  .app-promo__star {
    position: absolute;
    bottom: 39px;
    left: 50%;
    transform: translateX(calc(-50% - 133px));
    width: 16px;
    height: 16px;
    fill: var(--text-color-primary);
    z-index: 1;
  }

  .app-promo__img {
    position: relative;
    width: 172px;
    height: auto;
    z-index: 1;
  }

</style>
