var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValidationProvider", {
    attrs: { name: _vm.name, rules: _vm.validationRules, slim: "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ errors }) {
          return [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "body-click",
                    rawName: "v-body-click",
                    value: {
                      closeCb: _vm.onPhoneInputBlur,
                      condition: _vm.isFocused,
                    },
                    expression:
                      "{ closeCb: onPhoneInputBlur, condition: isFocused }",
                  },
                ],
                staticClass: "form-phone-input",
                class: {
                  "form-phone-input--error": errors.length > 0,
                  "form-phone-input--active":
                    _vm.isFocused || _vm.phoneNumber || errors.length > 0,
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.phoneNumber,
                      expression: "phoneNumber",
                    },
                  ],
                  ref: "inputPhoneNumber",
                  staticClass: "form-phone-input__field",
                  attrs: { id: "phone-number" },
                  domProps: { value: _vm.phoneNumber },
                  on: {
                    focus: function ($event) {
                      return _vm.onPhoneInputFocus()
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.phoneNumber = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.countryCodes.length > 0
                  ? _c("div", { staticClass: "form-phone-input__data" }, [
                      _c("span", {
                        staticClass: "form-phone-input__highlight",
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "form-phone-input__bar" }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-phone-input__label",
                          attrs: { for: "phone-number" },
                        },
                        [_vm._v("Phone Number")]
                      ),
                      _vm._v(" "),
                      errors.length > 0
                        ? _c(
                            "span",
                            { staticClass: "form-phone-input__error" },
                            [_vm._v(_vm._s(errors[0]))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-phone-input__button-wrapper" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "form-phone-input__dropdown-button",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleCodeDropdown()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "form-phone-input__img-wrapper",
                                },
                                [
                                  _c("img", {
                                    staticClass: "form-phone-input__img",
                                    attrs: {
                                      alt: "country",
                                      src: _vm.countryCode.flag,
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "svg",
                                { staticClass: "form-phone-input__ico" },
                                [_c("use", { attrs: { href: "#arrow-down" } })]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-phone-input__tooltip" },
                        [
                          _c("InputTooltip", [
                            _vm._v(
                              "\n          Entering your number please use international format and do not include the first "
                            ),
                            _c("b", [_vm._v("0")]),
                            _vm._v(" (zero)\n        "),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "body-click",
                              rawName: "v-body-click",
                              value: {
                                closeCb: _vm.closeCodeDropdown,
                                condition: _vm.isOpenedCodesDropdown,
                              },
                              expression:
                                "{ closeCb: closeCodeDropdown, condition: isOpenedCodesDropdown }",
                            },
                          ],
                          staticClass: "form-phone-input__dropdown",
                          class: {
                            "form-phone-input__dropdown--open":
                              _vm.isOpenedCodesDropdown,
                          },
                        },
                        [
                          _vm.isOpenedCodesDropdown
                            ? _c("CountryCodeDropdown", {
                                attrs: { "country-codes": _vm.countryCodes },
                                on: {
                                  "country-code-select": _vm.updateCountryCode,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }