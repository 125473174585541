var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "landing-testimonial" }, [
    _c("blockquote", { staticClass: "landing-testimonial__quote" }, [
      _vm._v(_vm._s(_vm.text)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "landing-testimonial__item" }, [
      _c("div", { staticClass: "landing-testimonial__box" }, [
        _c("img", {
          staticClass: "landing-testimonial__img",
          attrs: { src: "/images/landing/" + _vm.img + ".png", alt: "user" },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "landing-testimonial__box" }, [
        _c("p", { staticClass: "landing-testimonial__name" }, [
          _vm._v(_vm._s(_vm.name)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "landing-testimonial__title" }, [
          _vm._v(" " + _vm._s(_vm.title)),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }