var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-dropdown" }, [
    _c("label", { staticClass: "input-dropdown__search" }, [
      _c("input", {
        ref: "countrySearchInput",
        staticClass: "input-dropdown__input",
        attrs: { type: "search", tabindex: "0" },
        domProps: { value: _vm.searchField },
        on: {
          input: function ($event) {
            _vm.searchField = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("svg", { staticClass: "input-dropdown__ico" }, [
        _c("use", { attrs: { href: "#search" } }),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "input-dropdown__list" },
      _vm._l(_vm.filteredCountryCodes, function (countryCode) {
        return _c(
          "li",
          {
            staticClass: "input-dropdown__item",
            on: {
              click: function ($event) {
                return _vm.chooseCountryCode(countryCode)
              },
            },
          },
          [
            _c("div", { staticClass: "input-dropdown__img-wrapper" }, [
              _c("img", {
                staticClass: "input-dropdown__img",
                attrs: { src: countryCode.flag, alt: "country" },
              }),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "input-dropdown__text" }, [
              _vm._v(" " + _vm._s(countryCode.name) + " "),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }