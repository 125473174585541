<template>
  <div class="app" id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {};
</script>

<style src="@/assets/styles/variables.css"></style>
<style src="@/assets/styles/_import.css"></style>
<style>
  .app {
    height: 100%;
  }
</style>
