var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup", class: { "popup--open": _vm.isOpen } },
    [
      _c("i", { staticClass: "popup__overlay" }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "body-click",
              rawName: "v-body-click",
              value: { closeCb: _vm.closePopup, condition: _vm.isOpen },
              expression: "{ closeCb: closePopup, condition: isOpen }",
            },
          ],
          staticClass: "popup__content",
        },
        [
          _c(
            "button",
            {
              staticClass: "popup__close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.closePopup()
                },
              },
            },
            [
              _c("svg", { staticClass: "popup__ico" }, [
                _c("use", { attrs: { href: "#close" } }),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.title
            ? _c("div", { staticClass: "popup__header" }, [
                _c("p", { staticClass: "popup__title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "popup__data",
              class: { "popup__data--no-header": !_vm.title },
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }