<template>
  <div class="landing-hero" id="hero">
    <div class="landing-hero__wrapper wrapper">
      <div class="landing-hero__content">
        <div class="landing-hero__box landing-hero__box--content">
          <h1 class="landing-hero__title">Manage your workforce</h1>
          <p class="landing-hero__subtitle">easily and efficiently</p>
          <p class="landing-hero__text">A dedicated solution for workforce agencies who need to deploy employees to multiple client sites.</p>
          <div class="landing-hero__button-wrapper">
            <ButtonRippleEffect>
              <button
                type="button"
                class="landing-hero__button button button--primary button--large"
                @click="gotoProviderRegistration()"
              >Start Free Month Trial</button>
            </ButtonRippleEffect>
          </div>
          <p class="landing-hero__notice">No credit card required</p>
        </div>
        <div class="landing-hero__box landing-hero__box--img">

          <div class="landing-hero__media-wrapper">
            <img class="landing-hero__media"
                 src="/images/landing/hero-phone@1x.png"
                 srcset="/images/landing/hero-phone@1x.png 1x,
                   /images/landing/hero-phone@2x.png 2x"
                 alt="GoPlanr mobile platform example">
          </div>

          <div class="landing-hero__media-wrapper">
            <img class="landing-hero__media"
                 src="/images/landing/hero-desktop@1x.png"
                 srcset="/images/landing/hero-desktop@1x.png 1x,
                   /images/landing/hero-desktop@2x.png 2x"
                 alt="GoPlanr web platform example">
          </div>

          <div class="landing-hero__media-wrapper landing-hero__media-wrapper--video">
            <img class="landing-hero__img" src="/images/landing/desktop-header.png">
            <video class="landing-hero__media landing-hero__media--video" autoplay loop muted>
              <source src="/videos/landing/main.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>

          <svg class="landing-hero__ico landing-hero__ico--lines only-desktop">
            <use href="#lines"></use>
          </svg>
          <svg class="landing-hero__ico landing-hero__ico--star-left  only-desktop">
            <use href="#star"></use>
          </svg>
          <svg class="landing-hero__ico landing-hero__ico--star-right only-desktop">
            <use href="#star"></use>
          </svg>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import ButtonRippleEffect from "../Shared/ButtonRippleEffect";
  export default {
    components: { ButtonRippleEffect },
    methods: {
      gotoProviderRegistration() {
        window.location.href = '/sign/security-provider/registration/choose-plan';
      }
    }
  }
</script>

<style scoped>
  .landing-hero {
    position: relative;
    padding-top: 60px;
    background-color: var(--text-color-primary);
    overflow: hidden;
  }

  .landing-hero__wrapper {
    max-width: 1160px;
  }

  .landing-hero__content {
    @media (--viewport-desktop) {
      display: flex;
    }
  }

  .landing-hero__box--content {
    margin: 0 auto;
    max-width: 375px;
    text-align: center;

    @media (--viewport-desktop) {
      text-align: left;
      padding-right: 96px;
    }
  }

  .landing-hero__box--img {
    position: relative;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-size: 0;

    @media (--viewport-tablet) {
      display: flex;
      justify-content: center;
      height: auto;
      padding-bottom: 40px;
    }

    @media (--viewport-desktop) {
      justify-content: flex-end;
      text-align: right;
      padding-bottom: 140px;
    }

    &:after {
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateX(50%) translateY(50%);
      background: #FD7675;
      width: 576px;
      height: 464px;
      border-radius: 50%;

      @media (--viewport-tablet) {
        content: '';
      }

      @media (--viewport-desktop) {
        width: 144px;
        height: 144px;
        bottom: 50%;
        right: -40px;
        transform: translateX(0) translateY(calc(50% + 8px));
      }
    }

    &:before {
      position: absolute;
      bottom: 0;
      right: 24px;
      width: 288px;
      height: 250px;
      background: rgba(40, 67, 148, 0.5);
      clip-path: ellipse(100% 100% at 100% 100%);
      @media (--viewport-desktop) {
        content: '';
      }
    }

  }


  .landing-hero__title {
    margin-bottom: 8px;
    color: var(--color-white);
    font-size: 3rem;
    line-height: 1.1;
  }

  .landing-hero__subtitle {
    margin-bottom: 25px;
    color: var(--primary-color);
    font-size: 1.75rem;
    line-height: 1;
  }

  .landing-hero__text {
    margin-bottom: 80px;
    color: var(--color-white);
    font-size: 1rem;
    line-height: 1.625;

    @media (--viewport-desktop) {
      margin-bottom: 40px;
    }
  }

  .landing-hero__button-wrapper {
    display: inline-block;
    margin-bottom: 11px;
  }

  .landing-hero__button {
    width: 100%;

    @media (--viewport-tablet) {
      min-width: 256px;
      width: auto;
    }
  }

  .landing-hero__notice {
    margin-bottom: 52px;
    color: #5E71AF;
    font-size: 0.875rem;
    text-align: center;
    line-height: 1.2;
  }

  .landing-hero__media-wrapper {
    display: inline-block;
    position: relative;

    @media (--viewport-tablet) {
      display: none;
      max-width: 688px;
    }
    @media (--viewport-desktop) {
      max-width: 704px;
    }
  }

  .landing-hero__media-wrapper--video {
    display: none;

    @media (--viewport-tablet) {
      display: block;
    }

  }

  .landing-hero__img {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 100%;
    box-shadow: 0 4.40733px 13.222px rgba(95, 107, 123, 0.08);
  }

  .landing-hero__media {
    position: relative;
    height: 140px;
    width: auto;
    z-index: 1;

    @media (--viewport-tablet) {
      width: 100%;
      height: auto;
    }

    @media (--viewport-desktop) {
      height: 396px;
      width: auto;
    }

    &:first-child {
      margin-right: 16px;
      @media (--viewport-tablet) {
        margin-right: 0;
      }
    }
  }

  .landing-hero__media--video {
    padding-top: 23px;
  }

  .landing-hero__ico {
    position: absolute;
    width: 16px;
    height: 16px;
    fill: var(--primary-color-dark);
  }

  .landing-hero__ico--star-left {
    right: 704px;
    transform: translateX(50%);
    bottom: 62px;
  }

  .landing-hero__ico--star-right {
    right: -40px;
    top: 176px;
  }

  .landing-hero__ico--lines {
    bottom: 115px;
    right: -31px;
    width: 21px;
    height: 27px;
  }
</style>
