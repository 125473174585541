var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pricing-list" },
    _vm._l(_vm.getPlans(), function (plan) {
      return _c(
        "div",
        { staticClass: "pricing-list__item" },
        [
          _c("PricingPlan", {
            staticClass: "pricing-plan--counter",
            attrs: { "plan-details": plan, "with-quantity": true },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }