import "@babel/polyfill";

import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';

import App from './modules/App/AppLayout';
import router from './modules/App/Routes/AppRouter';
import store from './modules/App/Store/AppStore';

require('./core/Services/VeeValidate'); // todo all services
require('./core/Directives/BodyClick'); // todo all directives
require('./core/Directives/ObserveVisibility'); // todo all directives

require('./assets/sprite/index');

Vue.config.productionTip = false;

Vue.use(VueScrollTo, {
  container: "body",
  duration: 400,
  easing: 'linear',
});

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});
