import Vue from 'vue';

Vue.directive('body-click', {
  bind: function (el, binding) {
    el.listenToBodyClock = binding.value.condition;
    el.bodyClickHandler = (e) => {
      if (el.listenToBodyClock && binding.value.closeCb && !el.contains(e.target)) {
        binding.value.closeCb();
      }
    };
    document.addEventListener('click', el.bodyClickHandler);
  },
  update: function(el, newValue) {
    setTimeout(function() {
      el.listenToBodyClock = newValue.value.condition;
    });
  },
  unbind: function (el) {
    document.removeEventListener('click', el.bodyClickHandler);
  }
});
