<template>
  <div class="login">
    <ButtonRippleEffect class="ripple-outer--light">
      <a class="login__button button button--label" href="/sign/login">
        <svg class="login__ico">
          <use href="#login"/>
        </svg>
        Login
      </a>
    </ButtonRippleEffect>
  </div>
</template>
<script>
  import ButtonRippleEffect from './ButtonRippleEffect'

  export default {
    name: 'LoginButton',
    components: {ButtonRippleEffect}
  }
</script>
<style scoped>


  .login__ico {
    margin-right: 12px;
    width: 20px;
    height: 20px;
    fill: var(--primary-color);
  }

  .login {
    .login__button {
      @media (--viewport-tablet) {
        color: #FFFFFF;
      }
    }

    .login__ico {
      fill: #FFFFFF;
    }
  }

  .login--primary {
    .login__button {
      @media (--viewport-tablet) {
        color: var(--primary-color);
      }
    }

    .login__ico {
      fill: var(--primary-color);
    }
  }

</style>
