var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "landing-features" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "observe-visibility",
            rawName: "v-observe-visibility",
            value: {
              callback: (isVisible, entry) =>
                _vm.isViewableNow(isVisible, entry, "0"),
              once: true,
            },
            expression:
              "{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, '0'), once: true}",
          },
        ],
        staticClass: "landing-features__header",
        class: { visible: _vm.showAnimation[0] },
      },
      [
        _c("p", { staticClass: "landing-features__title section-title" }, [
          _vm._v("Features"),
        ]),
        _vm._v(" "),
        _c(
          "h2",
          {
            staticClass:
              "landing-features__title landing-features__title--secondary secondary-title",
          },
          [_vm._v("Transfer your operations to cloud")]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "landing-features__title landing-features__title--subtitle text-secondary",
          },
          [
            _vm._v(
              "GoPlanr is a feature rich platform allowing you to run your agency in real time in the cloud. Access and manage your operations at any time from anywhere in the world."
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "landing-features__list" },
      _vm._l(_vm.features, function (feature) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "observe-visibility",
                rawName: "v-observe-visibility",
                value: {
                  callback: (isVisible, entry) =>
                    _vm.isViewableNow(isVisible, entry, feature.id),
                  once: true,
                },
                expression:
                  "{callback: (isVisible, entry) => isViewableNow(isVisible, entry, feature.id), once: true}",
              },
            ],
            staticClass: "landing-features__item",
            class: { visible: _vm.showAnimation[feature.id] },
          },
          [_c("FeatureItem", { attrs: { featureDetails: feature } })],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }