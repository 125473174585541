<template>
  <div class="privacy-policy-back-button">
    <router-link class="privacy-policy-back-button__link" :to="{ name: 'landingHomePage' }">
      <svg class="privacy-policy-back-button__ico">
        <use href="#back"/>
      </svg>
    </router-link>
  </div>
</template>

<script>
  export default {}
</script>

<style scoped>
  .privacy-policy-back-button {
    position: fixed;
    top: 18px;
    left: 20px;
    width: 32px;
    height: 32px;
    z-index: 99;
    background-color: var(--color-white);
    border-radius: 50%;

    @media (--viewport-desktop) {
      top: 24px;
      left: 24px;
    }
  }

  .privacy-policy-back-button__link {
    display: block;
    width: 100%;
    height: 100%;
  }

  .privacy-policy-back-button__ico {
    width: 100%;
    height: 100%;
    fill: var(--text-color-tertiary);
  }
</style>
