var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact-form" },
    [
      !_vm.isShownSuccess
        ? _c("ValidationObserver", {
            attrs: { slim: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ handleSubmit }) {
                    return [
                      _c(
                        "form",
                        {
                          staticClass: "contact-form__section",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return handleSubmit(_vm.contactRequest)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "contact-form__list" },
                            [
                              _c("FormInput", {
                                attrs: {
                                  label: "Name",
                                  placeholder: "Enter your name",
                                  "validation-rules": "required",
                                },
                                model: {
                                  value: _vm.contactData.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.contactData, "name", $$v)
                                  },
                                  expression: "contactData.name",
                                },
                              }),
                              _vm._v(" "),
                              _c("FormInput", {
                                attrs: {
                                  label: "Email",
                                  placeholder: "Enter your email",
                                  inputType: "email",
                                  "validation-rules": "required|email",
                                },
                                model: {
                                  value: _vm.contactData.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.contactData, "email", $$v)
                                  },
                                  expression: "contactData.email",
                                },
                              }),
                              _vm._v(" "),
                              _c("FormInput", {
                                attrs: {
                                  label: "Message",
                                  placeholder: "Leave your message",
                                  "validation-rules": "required",
                                  isTextarea: "true",
                                },
                                model: {
                                  value: _vm.contactData.message,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.contactData, "message", $$v)
                                  },
                                  expression: "contactData.message",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "contact-form__item" },
                            [
                              _c("ButtonRippleEffect", [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "contact-form__button button button--primary button--small",
                                    attrs: { type: "submit" },
                                  },
                                  [_vm._v("Send")]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              4210030440
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShownSuccess
        ? _c(
            "div",
            {
              staticClass:
                "contact-form__section contact-form__section--success",
            },
            [
              _c("SuccessMessage", {
                attrs: {
                  title: "Thanks for contacting us!",
                  text:
                    _vm.contactData.name +
                    ", thank you for your interest in Goplanr. We will get back to you as soon as possible",
                },
                on: {
                  "button-click": function ($event) {
                    return _vm.closeContactPopup()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }