var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "success-message" }, [
    _c(
      "div",
      { staticClass: "success-message__item success-message__item--ico" },
      [
        _c("svg", { staticClass: "success-message__ico" }, [
          _c("use", { attrs: { "xlink:href": "#success" } }),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "success-message__item success-message__item--title" },
      [
        _c("p", { staticClass: "success-message__title" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "success-message__item success-message__item--text" },
      [
        _c("p", { staticClass: "success-message__text text-secondary" }, [
          _vm._v(_vm._s(_vm.text)),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "success-message__item" },
      [
        _c("ButtonRippleEffect", { staticClass: "ripple-outer--light" }, [
          _c(
            "button",
            {
              staticClass:
                "success-message__button button button--small button--stroke",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("button-click")
                },
              },
            },
            [_vm._v("\n        Got it\n      ")]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }