var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValidationProvider", {
    attrs: { name: _vm.name, rules: _vm.validationRules, slim: "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ errors }) {
          return [
            _c(
              "div",
              {
                staticClass: "form-input",
                class: {
                  "form-input--active":
                    _vm.isFocused || _vm.value || errors.length > 0,
                  "form-input--error": errors.length > 0,
                },
              },
              [
                !_vm.isTextarea
                  ? _c("input", {
                      staticClass: "form-input__field",
                      attrs: {
                        id: _vm.id,
                        type: _vm.inputType,
                        placeholder: _vm.placeholder,
                      },
                      domProps: { value: _vm.value },
                      on: {
                        input: function ($event) {
                          return _vm.$emit("input", $event.target.value)
                        },
                        focus: function ($event) {
                          return _vm.onInputFocus()
                        },
                        blur: function ($event) {
                          return _vm.onInputBlur()
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isTextarea
                  ? _c("textarea", {
                      ref: "textareaElement",
                      staticClass:
                        "form-input__field form-input__field--textarea",
                      attrs: {
                        id: _vm.id,
                        rows: "2",
                        type: _vm.inputType,
                        placeholder: _vm.placeholder,
                      },
                      domProps: { value: _vm.value },
                      on: {
                        input: _vm.updateTextarea,
                        focus: function ($event) {
                          return _vm.onInputFocus()
                        },
                        blur: function ($event) {
                          return _vm.onInputBlur()
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "form-input__data" }, [
                  _c("span", { staticClass: "form-input__highlight" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "form-input__bar" }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "form-input__label",
                      attrs: { for: _vm.id },
                    },
                    [_vm._v(_vm._s(_vm.label))]
                  ),
                  _vm._v(" "),
                  errors.length > 0
                    ? _c("span", { staticClass: "form-input__error" }, [
                        _vm._v(_vm._s(errors[0])),
                      ])
                    : _vm._e(),
                ]),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }