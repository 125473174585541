<template>
  <div class="landing-about">
    <div class="landing-about__wrapper wrapper">
      <div class="landing-about__content">
        <div class="landing-about__box only-desktop">
          <div class="landing-about__img-wrapper ">
            <img class="landing-about__img" src="/images/landing/about.png" alt="about-img">
          </div>
        </div>

        <div class="landing-about__box landing-about__box--content">
          <p class="landing-about__title landing-about__title section-title only-desktop">about</p>
          <h2 class="landing-about__title landing-about__title--secondary secondary-title">GoPlanr keeps all your employee management activities in the cloud</h2>
          <p class="landing-about__title landing-about__title--subtitle text-primary">GoPlanr is a unified software solution, that allows you to easily automate, organise and structure all your staff management operations in one place.</p>
          <div class="landing-about__img-wrapper only-mobile-tablet">
            <img class="landing-about__img" src="/images/landing/about.png" alt="about-img">
          </div>
          <div class="landing-about__testimonial">
            <LandingTestimonial
              class="landing-testimonial--white"
              text="“GoPlanr helped us to streamline our operations and back office activities giving us more time to concentrate on our clients and the service we provide.”"
              name="Denes Doman"
              title="Head of Operations at GC Covert Security"
              img="gccs-logo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import LandingTestimonial from './LandingTestimonial.vue'
  export default {
    components: {
      LandingTestimonial,
    }

  }
</script>

<style scoped>
  .landing-about {
    position: relative;
    padding-top: 75px;
    background-color: var(--light-background);

    @media (--viewport-tablet) {
      padding-top: 67px;
    }

    @media (--viewport-desktop) {
      padding-top: 51px;
    }

    &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 77px;
    background-color: var(--color-white);
    z-index: 1;

    @media (--viewport-tablet) {
      height: 104px;
    }
    }
  }

  .landing-about__wrapper {
    @media (--viewport-tablet) {
      max-width: 592px;
    }

    @media (--viewport-desktop) {
      max-width: 1160px;
    }
  }

  .landing-about__content {
    @media (--viewport-desktop) {
      display: flex;
    }
  }

  .landing-about__box {
    @media (--viewport-desktop) {
      width: 50%;
    }
  }

  .landing-about__box--content {
    @media (--viewport-desktop) {
      padding-top: 36px;
      max-width: 448px;
    }
  }



  .landing-about__title {
    margin-bottom: 16px;
    text-align: center;

    @media (--viewport-desktop) {
      margin-bottom: 12px;
      text-align: left;
      max-width: 78%;
    }
  }

  .landing-about__title--subtitle {
    margin-bottom: 29px;
    padding: 0 20px;
    text-align: justify;

    @media (--viewport-tablet) {
      margin-bottom: 40px;
      padding: 0 37px;
    }

    @media (--viewport-desktop) {
      margin-bottom: 61px;
      padding: 0;
    }

  }

  .landing-about__img-wrapper {
    margin: 0 auto 40px;
    text-align: center;
    width: 100%;
    max-width: 400px;

    @media (--viewport-tablet) {
      margin-bottom: 80px;
    }

    @media (--viewport-desktop) {
      margin: 0;
      text-align: left;
    }
  }

  .landing-about__img {
    width: 100%;
    height: auto;
  }

  .landing-about__testimonial {
    position: relative;
    z-index: 2;

    @media (--viewport-tablet) {
      padding: 0 32px;
    }

    @media (--viewport-desktop) {
      padding: 0;
    }
  }
</style>
