var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "privacy-policy-header" }, [
    _c(
      "div",
      {
        staticClass:
          "privacy-policy-header__box privacy-policy-header__box--logo",
      },
      [
        _c(
          "router-link",
          {
            staticClass: "privacy-policy-header__logo-link",
            attrs: { to: { name: "landingHomePage" } },
          },
          [
            _c("img", {
              staticClass: "privacy-policy-header__logo",
              attrs: { src: "/images/landing/gccs-logo.svg", alt: "logo" },
            }),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "privacy-policy-header__box" }, [
      _c(
        "h1",
        { staticClass: "privacy-policy-header__title secondary-title" },
        [_vm._v("Privacy Policy")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "privacy-policy-header__text text-secondary" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }