import Vue from 'vue';
import Vuex from 'vuex';
import signStore from '@/modules/Sign/Store/SignStore';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    sign: signStore
  }
});
