const PLAN_TYPE_STARTER = 1;
const PLAN_TYPE_BASIC = 2;
const PLAN_TYPE_PROFESSIONAL = 3;

const DEFAULT_EMPLOYEES_QUANTITY = 5;

export default {
  data() {
    return {
      getPlans() {
        return [{
          type: PLAN_TYPE_STARTER,
          name: 'Starter',
          ico: 'starter',
          features: ['Basic features', '5 users max', '1 location max'],
          buttonTitle: 'Sign Up',
          priceText: 'Forever',
          description: 'Check out some of the cool stuff we have',
          hint: 'Receive limited access to GoPlanr for free forever, upgrade anytime',
          employeesQuantity: DEFAULT_EMPLOYEES_QUANTITY
        }, {
          type: PLAN_TYPE_BASIC,
          name: 'Basic',
          ico: 'basic',
          price: 1.50,
          priceText: 'per month',
          pricePerUserText: 'per user, per month',
          features: ['Basic features', 'Unlimited users', 'Unlimited locations'],
          buttonTitle: 'Start Free Month Trial',
          description: 'Get access to all Goplanr Basic features for month for free',
          hint: 'You will be charged only after your trial period ends. You can cancel subscription anytime',
          employeesQuantity: DEFAULT_EMPLOYEES_QUANTITY
        }, {
          type: PLAN_TYPE_PROFESSIONAL,
          name: 'Professional',
          ico: 'professional',
          price: 2.50,
          priceText: 'per month',
          pricePerUserText: 'per user, per month',
          features: ['All professional features', 'Unlimited users', 'Unlimited locations'],
          buttonTitle: 'Start Free Month Trial',
          description: 'Get access to all Goplanr Pro features for month for free',
          hint: 'You will be charged only after your trial period ends. You can cancel subscription anytime',
          employeesQuantity: DEFAULT_EMPLOYEES_QUANTITY
        }];
      }
    }
  },
  methods: {
    isStarterPlan(type) {
      return type === PLAN_TYPE_STARTER;
    },
    isBasicPlan(type) {
      return type === PLAN_TYPE_BASIC;
    },
    isProfessionalPlan(type) {
      return type === PLAN_TYPE_PROFESSIONAL;
    }
  }
};
