import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

// https://logaretm.github.io/vee-validate/guide/interaction-and-ux.html#interaction-modes
// here we use combination of passive and aggressive
// https://github.com/logaretm/vee-validate/blob/master/src/modes.ts
setInteractionMode('passive-aggressive', ({ flags }) => {
  if (flags.validated) {
    return {
      on: ['input', 'blur']
    };
  }
  return { on: [] };
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('email', {
  ...email,
  message: 'Invalid email'
});
