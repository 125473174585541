var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pricing-header" }, [
    _c(
      "div",
      { staticClass: "pricing-header__box pricing-header__box--logo" },
      [
        _c(
          "router-link",
          {
            staticClass: "pricing-header__logo-link",
            attrs: { to: { name: "landingHomePage" } },
          },
          [
            _c("img", {
              staticClass: "pricing-header__logo",
              attrs: { src: "/images/landing/gccs-logo.svg", alt: "logo" },
            }),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing-header__box" }, [
      _c("h1", { staticClass: "pricing-header__title secondary-title" }, [
        _vm._v("Affordable tool for your smart management process"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "pricing-header__text text-secondary" }, [
        _vm._v(
          "Choose your plan and get access to the best market solution for workforce agencies who need to deploy employees to multiple client sites."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }