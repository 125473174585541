var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "landing-cta" }, [
    _c("div", { staticClass: "landing-cta__wrapper wrapper" }, [
      _c("div", { staticClass: "landing-cta__content" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "landing-cta__item landing-cta__item--button" },
          [
            _c(
              "ButtonRippleEffect",
              { staticClass: "ripple-outer--no-shadow" },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "landing-cta__button button button--large button--stroke-white",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.gotoProviderRegistration()
                      },
                    },
                  },
                  [_vm._v("Start Free Month Trial")]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("svg", { staticClass: "landing-cta__img" }, [
      _c("use", { attrs: { href: "#abstract-pricing" } }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "landing-cta__item landing-cta__item--text" },
      [
        _c("h3", { staticClass: "landing-cta__title" }, [
          _vm._v("Get started today"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "landing-cta__text text-tertiary" }, [
          _vm._v(
            "Experience all of Goplanr’s premium features with free trial, no credit card required"
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }