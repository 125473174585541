<template>
  <div class="segmented-control">
    <label class="segmented-control__label" v-for="option in options">
      <input
        class="segmented-control__input"
        :id="option.id"
        :name="name"
        :checked="option.id === value"
        type="radio"
        v-bind:value="option.id"
        v-on:input="$emit('input', $event.target.value)"
      >
      <span class="segmented-control__data">
        <ButtonRippleEffect class="ripple-outer--light">
          <span class="segmented-control__text"> {{ option.text }}</span>
        </ButtonRippleEffect>
      </span>
    </label>
  </div>
</template>

<script>
  import ButtonRippleEffect from '../../Shared/ButtonRippleEffect'
  export default {
    components: {ButtonRippleEffect},
    props: ['value', 'name', 'options']
  }
</script>

<style scoped>
  .segmented-control {
    display: flex;
  }

  .segmented-control__label {
    &:last-child {
      .segmented-control__text {
        border-radius: 0 2px 2px 0;
        border-left: none;
      }
    }
  }

  .segmented-control__input {
    position: absolute;
    clip: rect(0,0,0,0);
    width: 0;
    height: 0;

    &:checked + .segmented-control__data {
      .segmented-control__text {
        font-weight: 600;
        color: var(--primary-color);
        background-color: #EBF8FF;
      }
    }
  }

  .segmented-control__text {
    display: flex;
    align-items: center;
    height: 30px;
    width: 80px;
    padding: 8px;
    font-size: 0.875rem;
    line-height: 1;
    color: var(--text-color-tertiary);
    transition: .3s ease;
    box-sizing: border-box;
    border: 1px solid var(--primary-color);
    border-radius: 2px 0 0 2px;
  }
</style>
