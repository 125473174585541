<template>
  <div class="app-promo-links">
    <div class="app-promo-links__item">
      <a class="app-promo-links__link" href="https://play.google.com/store/apps/details?id=gccs.mobile" target="_blank">
        <svg class="app-promo-links__ico">
          <use href="#appstore-google"></use>
        </svg>
      </a>
    </div>
    <div class="app-promo-links__item">
      <a class="app-promo-links__link" href="https://itunes.apple.com/app/goplanr/id1202929922" target="_blank">
        <svg class="app-promo-links__ico">
          <use href="#appstore-apple"></use>
        </svg>
      </a>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'AppPromoLinks'
  }
</script>
<style scoped>

  .app-promo-links {
    @media (--viewport-tablet) {
      display: flex;
    }
  }

  .app-promo-links--description {
    @media (--viewport-tablet) {
      .app-promo-links__ico {
        width: 180px;
        height: 56px;
      }

      .app-promo-links__item {
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }

  .app-promo-links__item {
    display: flex;
    justify-content: center;

    @media (--viewport-tablet) {
      justify-content: flex-start;
    }

    &:not(:last-child) {
      margin-bottom: 20px;

      @media (--viewport-tablet) {
        margin-bottom: 0;
        margin-right: 8px;
      }
    }

  }

  .app-promo-links__ico {
    width: 180px;
    height: 56px;

    @media (--viewport-tablet) {
      width: 130px;
      height: 40px;
    }
  }

  .app-promo-links__link {
    display: inline-block;
  }

</style>
