var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "landing-app-description" }, [
    _c("div", { staticClass: "landing-app-description__wrapper wrapper" }, [
      _c("div", { staticClass: "landing-app-description__content" }, [
        _c("p", { staticClass: "landing-app-description__title" }, [
          _vm._v("Download the GoPlanr App for Employees"),
        ]),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "landing-app-description__text text-secondary" },
          [
            _vm._v(
              "If your employer has asked you to download the GoPlanr App to manage your schedule, please click to download the IOS or Android app from the app store"
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "landing-app-description__list" },
          [
            _c("AppPromoLinks", {
              staticClass: "app-promo-links--description",
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("img", {
        staticClass: "landing-app-description__img",
        attrs: { src: "/images/landing/goplanr-app.png" },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }