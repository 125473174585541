<template>
  <div class="landing-cta">
    <div class="landing-cta__wrapper wrapper">
      <div class="landing-cta__content">
        <div class="landing-cta__item landing-cta__item--text">
          <h3 class="landing-cta__title">Get started today</h3>
          <p class="landing-cta__text text-tertiary">Experience all of Goplanr’s premium features with free trial, no credit card required</p>
        </div>
        <div class="landing-cta__item landing-cta__item--button">
          <ButtonRippleEffect class="ripple-outer--no-shadow">
            <button
              type="button"
              class="landing-cta__button button button--large button--stroke-white"
              @click="gotoProviderRegistration()"
            >Start Free Month Trial</button>
          </ButtonRippleEffect>
        </div>
      </div>
    </div>
    <svg class="landing-cta__img">
      <use href="#abstract-pricing"></use>
    </svg>
  </div>
</template>

<script>
  import ButtonRippleEffect from "../Shared/ButtonRippleEffect";
  export default {
    components: { ButtonRippleEffect },
    methods: {
      gotoProviderRegistration() {
        window.location.href = '/sign/security-provider/registration/choose-plan';
      }
    }
  }
</script>

<style scoped>
  .landing-cta {
    position: relative;
    background-color: var(--text-color-primary);
    overflow: hidden;

    @media (--viewport-tablet) {
      height: 200px;
    }


    &:after {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(75%) translateY(50%);
      background: rgba(40, 67, 148, 0.5);
      width: 576px;
      height: 576px;
      border-radius: 50%;

      @media (--viewport-tablet) {
        content: '';


      }
    }
  }

  .landing-cta__wrapper {
    height: 100%;

    @media (--viewport-desktop) {
      padding-right: 115px;
    }
  }

  .landing-cta__content {
    height: 100%;
    padding: 29px 0 46px;
    box-sizing: border-box;

    @media (--viewport-tablet) {
      padding: 29px 32px 46px;
      display: flex;
      align-items: center;
    }

    @media (--viewport-desktop) {
      padding: 29px 0 46px;
    }
  }

  .landing-cta__item--text {
    flex-grow: 1;
  }


  .landing-cta__title {
    margin-bottom: 14px;
    color: var(--color-white);
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.25;
    text-align: center;

    @media (--viewport-tablet) {
      margin-bottom: 6px;
      text-align: left;
      max-width: 320px;
    }

    @media (--viewport-desktop) {
      max-width: none;
    }
  }

  .landing-cta__text {
    margin-bottom: 31px;
    text-align: center;
    @media (--viewport-tablet) {
      margin-bottom: 0;
      text-align: left;
      max-width: 320px;
    }

    @media (--viewport-desktop) {
      max-width: none;
    }
  }

  .landing-cta__item--button {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 1;
  }

  .landing-cta__img {
    display: none;
    width: 188px;
    height: 164px;
    position: absolute;
    bottom: -50px;
    left: -48px;

    @media (--viewport-desktop) {
      display: block;
    }

  }


</style>
