var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pricing-testimonials" }, [
    _c(
      "div",
      { staticClass: "pricing-testimonials__list" },
      _vm._l(_vm.testimonials, function (item) {
        return _c(
          "div",
          { staticClass: "pricing-testimonials__item" },
          [
            _c("LandingTestimonial", {
              staticClass: "landing-testimonial--flex",
              attrs: {
                text: item.text,
                name: item.name,
                title: item.title,
                img: item.img,
              },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }