var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pricing" },
    [
      _c("PricingBackButton"),
      _vm._v(" "),
      _c("div", { staticClass: "pricing__section pricing__section--header" }, [
        _c(
          "div",
          { staticClass: "pricing__wrapper wrapper" },
          [_c("PricingHeader")],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pricing__section pricing__section--list" }, [
        _c(
          "div",
          { staticClass: "pricing__wrapper wrapper" },
          [_c("PricingList")],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pricing__section pricing__section--table" }, [
        _c(
          "div",
          { staticClass: "pricing__wrapper pricing__wrapper--table wrapper" },
          [_c("PricingTable")],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pricing__section pricing__section--testimonials" },
        [
          _c(
            "div",
            {
              staticClass:
                "pricing__wrapper pricing__wrapper--testimonials wrapper",
            },
            [_c("PricingTestimonials")],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }