var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "booking-form" },
    [
      _c(
        "div",
        { staticClass: "booking-form__wrapper wrapper" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "observe-visibility",
                  rawName: "v-observe-visibility",
                  value: {
                    callback: (isVisible, entry) =>
                      _vm.isViewableNow(isVisible, entry, "header"),
                    once: true,
                  },
                  expression:
                    "{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, 'header'), once: true}",
                },
              ],
              staticClass: "booking-form__header",
              class: { visible: _vm.showAnimation.header },
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "booking-form__title booking-form__title--section section-title",
                },
                [_vm._v("HAVING QUESTIONS?")]
              ),
              _vm._v(" "),
              _c(
                "h2",
                {
                  staticClass:
                    "booking-form__title booking-form__title--secondary secondary-title",
                },
                [_vm._v("Book a demo")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "booking-form__title booking-form__title--subtitle text-primary",
                },
                [
                  _vm._v(
                    "\n        Please contact us below for a free demo, or if you have any questions or requests concerning our services.\n      "
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          !_vm.isShownSuccess
            ? _c("ValidationObserver", {
                directives: [
                  {
                    name: "observe-visibility",
                    rawName: "v-observe-visibility",
                    value: {
                      callback: (isVisible, entry) =>
                        _vm.isViewableNow(isVisible, entry, "form"),
                      once: true,
                    },
                    expression:
                      "{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, 'form'), once: true}",
                  },
                ],
                class: { visible: _vm.showAnimation.form },
                attrs: { slim: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ handleSubmit }) {
                        return [
                          _c(
                            "form",
                            {
                              staticClass: "booking-form__section",
                              attrs: { novalidate: "" },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return handleSubmit(_vm.bookRequest)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "booking-form__item" },
                                [
                                  _c("FormInput", {
                                    attrs: {
                                      label: "Name",
                                      placeholder: "Enter your name",
                                      "validation-rules": "required",
                                    },
                                    model: {
                                      value: _vm.bookingData.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.bookingData, "name", $$v)
                                      },
                                      expression: "bookingData.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "booking-form__item" },
                                [
                                  _c("FormInput", {
                                    attrs: {
                                      label: "Email",
                                      placeholder: "Enter your email",
                                      "input-type": "email",
                                      "validation-rules": "required|email",
                                    },
                                    model: {
                                      value: _vm.bookingData.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.bookingData, "email", $$v)
                                      },
                                      expression: "bookingData.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "booking-form__item" },
                                [
                                  _c("FormPhoneInput", {
                                    attrs: { "validation-rules": "required" },
                                    model: {
                                      value: _vm.bookingData.phoneNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.bookingData,
                                          "phoneNumber",
                                          $$v
                                        )
                                      },
                                      expression: "bookingData.phoneNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "booking-form__item booking-form__item--options",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "booking-form__text" },
                                    [_vm._v("I am an:")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "booking-form__options" },
                                    [
                                      _c("SegmentedControl", {
                                        attrs: {
                                          name: "employmentType",
                                          options: [
                                            {
                                              id: "employer",
                                              text: "Employer",
                                            },
                                            {
                                              id: "employee",
                                              text: "Employee",
                                            },
                                          ],
                                        },
                                        model: {
                                          value: _vm.bookingData.employmentType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.bookingData,
                                              "employmentType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "bookingData.employmentType",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "booking-form__item booking-form__item--button",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "booking-form__button-wrapper",
                                    },
                                    [
                                      _c("ButtonRippleEffect", [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "booking-form__button button button--primary button--large",
                                            attrs: { type: "submit" },
                                          },
                                          [_vm._v("Book a demo")]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "booking-form__notice" },
                                    [
                                      _vm._v(
                                        "By submitting this form you agree to our terms. See our\n            "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "booking-form__link",
                                          attrs: {
                                            href: "javascript:void(0);",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.isShownPrivacyPolicy = true
                                            },
                                          },
                                        },
                                        [_vm._v("privacy policy")]
                                      ),
                                      _vm._v(
                                        "\n            to learn about how we use your information.\n          "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1244392316
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "LandingPopup",
        {
          attrs: { "is-open": _vm.isShownSuccess },
          on: {
            close: function ($event) {
              return _vm.closeSuccessPopup()
            },
          },
        },
        [
          _c("SuccessMessage", {
            attrs: {
              title: "Demo request sent",
              text:
                _vm.bookingData.name +
                ", thank you for your interest in Goplanr. We will get back to you as soon as possible",
            },
            on: {
              "button-click": function ($event) {
                return _vm.closeSuccessPopup()
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "LandingPopup",
        {
          staticClass: "popup--policy",
          attrs: {
            title: "GoPlanr Privacy Policy",
            "is-open": _vm.isShownPrivacyPolicy,
          },
          on: {
            close: function ($event) {
              _vm.isShownPrivacyPolicy = false
            },
          },
        },
        [_c("PrivacyPolicy")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }