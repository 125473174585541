var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "terms-and-conditions simple-text" }, [
      _c(
        "a",
        {
          staticClass:
            "iubenda-nostyle no-brand iubenda-embed iub-no-markup iub-body-embed",
          attrs: {
            href: "https://www.iubenda.com/terms-and-conditions/73958017",
            title: "Terms and Conditions",
          },
        },
        [_vm._v("Terms and Conditions")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }