var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "landing-advantages" }, [
    _c("div", { staticClass: "landing-advantages__wrapper wrapper" }, [
      _c("div", { staticClass: "landing-advantages__content" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "observe-visibility",
                rawName: "v-observe-visibility",
                value: {
                  callback: (isVisible, entry) =>
                    _vm.isViewableNow(isVisible, entry, "a"),
                  once: true,
                },
                expression:
                  "{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, 'a'), once: true}",
              },
            ],
            staticClass: "landing-advantages__header",
            class: { visible: _vm.showAnimation.a },
          },
          [
            _c(
              "p",
              { staticClass: "landing-advantages__title section-title" },
              [_vm._v("ADVANTAGES")]
            ),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticClass:
                  "landing-advantages__title landing-advantages__title--secondary secondary-title",
              },
              [_vm._v("Win clients by giving them the transparency they crave")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "landing-advantages__title landing-advantages__title--subtitle text-secondary",
              },
              [
                _vm._v(
                  "Your clients always want to know who they have working on their sites and GoPlanr allows you to give them access so they can view their schedule in real time."
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "ul",
          {
            directives: [
              {
                name: "observe-visibility",
                rawName: "v-observe-visibility",
                value: {
                  callback: (isVisible, entry) =>
                    _vm.isViewableNow(isVisible, entry, "b"),
                  once: true,
                },
                expression:
                  "{ callback: (isVisible, entry) => isViewableNow(isVisible, entry, 'b'), once: true}",
              },
            ],
            staticClass: "landing-advantages__list",
            class: { visible: _vm.showAnimation.b },
          },
          [
            _c("li", { staticClass: "landing-advantages__item" }, [
              _c("div", { staticClass: "landing-advantages__data" }, [
                _c("svg", { staticClass: "landing-advantages__ico" }, [
                  _c("use", { attrs: { href: "#advantages-portal" } }),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "landing-advantages__name" }, [
                  _vm._v(" Client Portal"),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "landing-advantages__text text-secondary" },
                  [
                    _vm._v(
                      " Access to Client Portal in order to assist clients needs "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "landing-advantages__item" }, [
              _c("div", { staticClass: "landing-advantages__data" }, [
                _c("svg", { staticClass: "landing-advantages__ico" }, [
                  _c("use", { attrs: { href: "#advantages-shift" } }),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "landing-advantages__name" }, [
                  _vm._v(" Real-time schedule"),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "landing-advantages__text text-secondary" },
                  [
                    _vm._v(
                      " View real-time schedule to see who is working on clients sites "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "landing-advantages__item" }, [
              _c("div", { staticClass: "landing-advantages__data" }, [
                _c("svg", { staticClass: "landing-advantages__ico" }, [
                  _c("use", { attrs: { href: "#advantages-reports" } }),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "landing-advantages__name" }, [
                  _vm._v("Detailed financial and HR reports"),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "landing-advantages__text text-secondary" },
                  [
                    _vm._v(
                      " Increase transparency and client engagement in the service you provide "
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }