<template>
  <div class="contact-form">
    <ValidationObserver v-if="!isShownSuccess" v-slot="{ handleSubmit }" slim>
      <form class="contact-form__section" @submit.prevent="handleSubmit(contactRequest)">
        <div class="contact-form__list">
          <FormInput
            label="Name"
            placeholder="Enter your name"
            v-model="contactData.name"
            validation-rules="required"
          />

          <FormInput
            label="Email"
            placeholder="Enter your email"
            inputType="email"
            v-model="contactData.email"
            validation-rules="required|email"
          />

          <FormInput
            label="Message"
            placeholder="Leave your message"
            v-model="contactData.message"
            validation-rules="required"
            isTextarea="true"
          />
        </div>

        <div class="contact-form__item">
          <ButtonRippleEffect>
            <button type="submit" class="contact-form__button button button--primary button--small">Send</button>
          </ButtonRippleEffect>

        </div>
      </form>
    </ValidationObserver>

    <div v-if="isShownSuccess" class="contact-form__section contact-form__section--success">
      <SuccessMessage
        title="Thanks for contacting us!"
        :text="contactData.name + ', thank you for your interest in Goplanr. We will get back to you as soon as possible'"
        v-on:button-click="closeContactPopup()"
      />
    </div>
  </div>
</template>

<script>
  import SuccessMessage from './SuccessMessage';
  import FormInput from './Input/FormInput';
  import ButtonRippleEffect from '../Shared/ButtonRippleEffect';

  import axios from '@/core/Services/Axios';

  export default {
    components: {
      FormInput,
      SuccessMessage,
      ButtonRippleEffect

    },
    data() {
      return {
        isShownSuccess: false,
        contactData: {}
      }
    },
    created() {
      this.resetContactData();
    },
    methods: {
      contactRequest() {
        axios.post('/landing/contact_us', { contact_us: this.contactData });
        this.isShownSuccess = true;
      },
      resetContactData() {
        this.contactData = {
          name: '',
          email: '',
          message: '',
        };
      },
      closeContactPopup() {
        this.resetContactData();
        this.isShownSuccess = false;
        this.$emit('close-contact-popup');
      }
    }
  }
</script>

<style scoped>
  .contact-form__list {
    margin-bottom: 5px;

    @media (--viewport-tablet) {
      margin-bottom: 37px;
    }

  }

  .contact-form__item {
    display: flex;
    justify-content: center;
  }

  .contact-form__section--success {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 80px;
    background-color: var(--color-white);
    z-index: 1;
    box-sizing: border-box;
  }
</style>
