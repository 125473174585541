var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "segmented-control" },
    _vm._l(_vm.options, function (option) {
      return _c("label", { staticClass: "segmented-control__label" }, [
        _c("input", {
          staticClass: "segmented-control__input",
          attrs: { id: option.id, name: _vm.name, type: "radio" },
          domProps: { checked: option.id === _vm.value, value: option.id },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "segmented-control__data" },
          [
            _c("ButtonRippleEffect", { staticClass: "ripple-outer--light" }, [
              _c("span", { staticClass: "segmented-control__text" }, [
                _vm._v(" " + _vm._s(option.text)),
              ]),
            ]),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }