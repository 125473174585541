var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-promo" }, [
    _c("div", { staticClass: "app-promo__wrapper wrapper" }, [
      _c("div", { staticClass: "app-promo__content" }, [
        _c("div", { staticClass: "app-promo__box app-promo__box--content" }, [
          _c("p", { staticClass: "app-promo__title" }, [
            _vm._v(
              "\n          Download the GoPlanr App for Employees\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "app-promo__list" },
            [_c("AppPromoLinks")],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "app-promo__box app-promo__box--img" }, [
          _c("div", { staticClass: "app-promo__media" }, [
            _c("div", { staticClass: "app-promo__img-wrapper" }, [
              _c("svg", { staticClass: "app-promo__star" }, [
                _c("use", { attrs: { href: "#star" } }),
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "app-promo__img",
                attrs: {
                  src: "images/landing/app-screen@1x.png",
                  srcset:
                    "/images/landing/app-screen@1x.png" +
                    " 1x, " +
                    "/images/landing/app-screen@2x.png" +
                    " 2x",
                  alt: "Example of GoPlanr app",
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }