<template>
  <div class="pricing-list">
    <div v-for="plan in getPlans()" class="pricing-list__item">
      <PricingPlan
        class="pricing-plan--counter"
        :plan-details="plan"
        :with-quantity="true"
      />
    </div>
  </div>
</template>

<script>
  import PricingPlan from './PricingPlan';
  import PricingPlanMixin from '../../Mixins/PricingPlan';

  export default {
    components: { PricingPlan },
    mixins: [PricingPlanMixin]
  }
</script>

<style scoped>
  .pricing-list {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--viewport-desktop) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      margin: 0 -16px 46px;
    }
  }

  .pricing-list__item {
    width: 100%;
    max-width: 368px;
    box-sizing: border-box;

    @media (--viewport-desktop) {
      width: 33.33%;
      max-width: none;
      padding: 0 16px;
    }
  }

</style>
