var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-promo-links" }, [
    _c("div", { staticClass: "app-promo-links__item" }, [
      _c(
        "a",
        {
          staticClass: "app-promo-links__link",
          attrs: {
            href: "https://play.google.com/store/apps/details?id=gccs.mobile",
            target: "_blank",
          },
        },
        [
          _c("svg", { staticClass: "app-promo-links__ico" }, [
            _c("use", { attrs: { href: "#appstore-google" } }),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "app-promo-links__item" }, [
      _c(
        "a",
        {
          staticClass: "app-promo-links__link",
          attrs: {
            href: "https://itunes.apple.com/app/goplanr/id1202929922",
            target: "_blank",
          },
        },
        [
          _c("svg", { staticClass: "app-promo-links__ico" }, [
            _c("use", { attrs: { href: "#appstore-apple" } }),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }